import React from 'react'
import { Navigation, Pagination, Scrollbar, A11y, EffectCreative } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from 'react-responsive';
import CustomCursor from '../components/customCursor';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-creative';
import HeroVeena from '../components/projectsComponents/HeroVeena'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer';
import TextComponent from '../components/projectsComponents/TextComponent'
import birdeye1 from '../assets/images/projects/veenavihar/birdeye1.jpeg';
import birdeye2 from '../assets/images/projects/veenavihar/birdeye2.jpeg';
import blockA1 from '../assets/images/projects/veenavihar/a/VBAFP.jpeg';
import blockA2 from '../assets/images/projects/veenavihar/a/VBAISO1.jpeg';
import blockA3 from '../assets/images/projects/veenavihar/a//VBAISO2.jpeg';
import blockA4 from '../assets/images/projects/veenavihar/a/VBAISO3.jpeg';
import blockA5 from '../assets/images/projects/veenavihar/a/VBAISO4.jpeg';
import blockA6 from '../assets/images/projects/veenavihar/a/VBAISO5.jpeg';
import blockA7 from '../assets/images/projects/veenavihar/a/VBAISO6.jpeg';
import blockB from '../assets/images/projects/veenavihar/VBBFP.jpeg';
import blockC from '../assets/images/projects/veenavihar/VBCFP.jpeg';
import blockD from '../assets/images/projects/veenavihar/VBDFP.jpeg';
import blockE from '../assets/images/projects/veenavihar/VBEFP.jpeg';

const VeenaVihar = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    return (
        <div className='bg-[#E6E9F4]'>
            {!isMobile && (
                <CustomCursor />
            )}
            <Navbar bgColor="black" headerColor="[#E6E9F4]" />
            <HeroVeena />

            {/* "BIRD EYE VIEW" */}
            <div className='birdeye'>
                <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                    <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Bird Eye View</p>
                </div>
                <div className=''>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Scrollbar, A11y, EffectCreative, Pagination]}
                        pagination={{ clickable: true }}
                        spaceBetween={25}
                        slidesPerView={1}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        effect='creative'
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-121%', 0, -500],
                            },
                            next: {
                                shadow: true,
                                translate: ['121%', 0, -500],
                            },
                        }}
                    >
                        <SwiperSlide><img src={birdeye1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={birdeye2} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>

                    </Swiper>
                </div>
            </div>

            <TextComponent heading="The Shine of Your Lifestyle" desc="Veena Vihar is one of the most awaited and prestigious projects by Concicon Constructions which is situated at one of the best location of the Patna city. It is one of those strategically planned apartments which promise ceaseless comfort and convenience to people." />

            {/* "Block A1" */}
            <div className='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'>
                <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                    <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Block A</p>
                </div>
                <div className=''>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative]}
                        pagination={{ clickable: true }}
                        spaceBetween={25}
                        slidesPerView={1}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        effect='creative'
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-121%', 0, -500],
                            },
                            next: {
                                shadow: true,
                                translate: ['121%', 0, -500],
                            },
                        }}
                    >
                        <SwiperSlide><img src={blockA1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA2} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA3} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA4} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA5} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA6} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA7} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                    </Swiper>
                </div>
            </div>

            {/* "Block B" */}
            <div className='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'>
                <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                    <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Block B</p>
                </div>
                <div className=''>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative]}
                        pagination={{ clickable: true }}
                        spaceBetween={25}
                        slidesPerView={1}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        effect='creative'
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-121%', 0, -500],
                            },
                            next: {
                                shadow: true,
                                translate: ['121%', 0, -500],
                            },
                        }}
                    >
                        <SwiperSlide><img src={blockB} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>

                    </Swiper>
                </div>
            </div>

            {/* "Block C" */}
            <div className='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'>
                <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                    <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Block C</p>
                </div>
                <div className=''>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative]}
                        pagination={{ clickable: true }}
                        spaceBetween={25}
                        slidesPerView={1}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        effect='creative'
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-121%', 0, -500],
                            },
                            next: {
                                shadow: true,
                                translate: ['121%', 0, -500],
                            },
                        }}
                    >
                        <SwiperSlide><img src={blockC} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>

                    </Swiper>
                </div>
            </div>

            {/* "Block D" */}
            <div className='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'>
                <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                    <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Block D</p>
                </div>
                <div className=''>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative]}
                        pagination={{ clickable: true }}
                        spaceBetween={25}
                        slidesPerView={1}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        effect='creative'
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-121%', 0, -500],
                            },
                            next: {
                                shadow: true,
                                translate: ['121%', 0, -500],
                            },
                        }}
                    >
                        <SwiperSlide><img src={blockD} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>

                    </Swiper>
                </div>
            </div>

            {/* "Block E" */}
            <div className='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36 pb-20'>
                <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                    <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Block E</p>
                </div>
                <div className=''>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative]}
                        pagination={{ clickable: true }}
                        spaceBetween={25}
                        slidesPerView={1}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        effect='creative'
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-121%', 0, -500],
                            },
                            next: {
                                shadow: true,
                                translate: ['121%', 0, -500],
                            },
                        }}
                    >
                        <SwiperSlide><img src={blockE} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>

                    </Swiper>
                </div>
            </div>

        </div>
    )
}

export default VeenaVihar