import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import useOnScreen from "../../hooks/useOnScreen";
import cn from "classnames";

import FeaturedImg from '../../assets/images/sarvodaya-birdeye.jpeg';
import shoppersMall from '../../assets/images/shoppers.jpeg';
import tranquil from '../../assets/images/tranquil.jpeg';
import veenaVihar from '../../assets/images/veenanight.png';

import "./style.scss";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);



const images = [
  {
    src: FeaturedImg,
    title: "Sarvodaya City",
    subtitle: "Live the Beauty",
    category: "Residential / Apartments",
    link: "/projects/sarvodaya"
  },
  {
    src: tranquil,
    title: "Tranquil",
    subtitle: "Live the Beauty",
    category: "Residential / Apartments",
    link: "projects/tranquil"
  },
  {
    src: veenaVihar,
    title: "Veena Vihar",
    subtitle: "Live the Beauty",
    category: "Apartment / Residential",
    link: "projects/veenavihar"
  },
  {
    src: shoppersMall,
    title: "Shoppers Mall",
    subtitle: "Living Room",
    category: "Mall / Public",
    link: "projects/shoppersmall"
  },
];
function GalleryItem({
  src,
  category,
  subtitle,
  title,
  updateActiveImage,
  index,
  link
}) {
  const ref = useRef(null);

  const onScreen = useOnScreen(ref, 0.5);

  useEffect(() => {
    if (onScreen) {
      updateActiveImage(index);
    }
  }, [onScreen, index, updateActiveImage]);

  return (
    <div
      className={cn("gallery-item-wrapper", { "is-reveal": onScreen })}
      ref={ref}
    >
      <div></div>
      <div className={"gallery-item text-beige"}>
        <div className="gallery-item-info">
          <h1 className="gallery-info-title">{title}</h1>
          <h2 className="gallery-info-subtitle">{subtitle}</h2>
          <p className="gallery-info-category">{category}</p>
        </div>
        <a href={link}>
          <div
            className="gallery-item-image"
            style={{ backgroundImage: `url(${src})` }}
          ></div>
        </a>

      </div>
      <div></div>
    </div>
  );
}

export default function Gallery({ src, index, columnOffset }) {
  const [activeImage, setActiveImage] = useState(1);
  const galleryWrap = useRef(null);

  const ref = useRef(null);
  // let tl = gsap
  //   .timeline({
  //     scrollTrigger: {
  //       trigger: ".gallery-wrap",
  //       start: "top top",
  //       end: "bottom bottom",
  //       scrub: 1,
  //       markers: true
  //     }
  //   })
  function CalculateScrollAmount() {
    return -(ref.current.offsetWidth - galleryWrap.current.offsetWidth)
  }
  useGSAP(() => {
    // This does not seem to work without a settimeout

    // console.log(ref.current.offsetWidth);
    // console.log(ref.current.clientWidth);
    // console.log({ current: ref.current });
    // console.log(galleryWrap.current.offsetWidth)
    // let sections = gsap.utils.toArray(".gallery-item-wrapper");

    // gsap.to(sections, {
    //   xPercent: -100 * (sections.length - 1),
    //   ease: "none",
    //   scrollTrigger: {
    //     start: "top top",
    //     trigger: ref.current,
    //     scroller: "#main-container",
    //     pin: true,
    //     scrub: 0.5,
    //     snap: 1 / (sections.length - 1),
    //     end: () => `+=${ref.current.offsetWidth}`,
    //   },
    // });
    // console.log(CalculateScrollAmount())
    // console.log(`+=${CalculateScrollAmount() * -1}`)
    gsap.to(".gallery", {
      x: CalculateScrollAmount(),
      ease: "none",
      scrollTrigger: {
        trigger: '.gallery-wrap',
        start: 'top top',
        end: `+=${CalculateScrollAmount() * -1}`,
        scrub: 0.5,
        pin: true,
        invalidateOnRefresh: true
      }
    })

  }, []);

  const handleUpdateActiveImage = (index) => {
    setActiveImage(index + 1);
  };

  return (
    <section data-scroll-section className="section-wrapper gallery-wrap bg-black" ref={galleryWrap}>
      <div className="gallery" ref={ref}>

        {/* <div className="gallery-counter fixed">
          <span>{activeImage}</span>
          <span className="divider" />
          <span>{images.length}</span>
        </div> */}

        {images.map((image, index) => (
          <GalleryItem
            key={src}
            index={index}
            {...image}
            updateActiveImage={handleUpdateActiveImage}
          />
        ))}
        <a href="/all-projects" className="flex pb-48">
          <button className="flex items-center self-center whitespace-nowrap button-bg-color text-black text-sm md:text-base lg:text-lg py-3 xl:py-4 px-7 md:px-8 lg:px-9 xl:px-10 rounded-full mb-10 mr-12 font-interMedium">
            <p>All Projects</p>
            <svg
              class="w-3 h-3 md:w-3.5 md:h-3.5 ml-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </button>
        </a>
      </div>
    </section>
  );
}
