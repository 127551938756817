import React from 'react'

const TextComponent = (props) => {
    return (
        <div className={`${props.margin}`}>
            <div className='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'>
                <div className='flex flex-col md:flex-row justify-between mt-16 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28 pb-4'>
                    <p className=' md:w-2/5 text-[1.05rem] sm:text-[1.2rem] md:text-[1.5rem] lg:text-[1.7rem] xl:text-[1.9rem] 2xl:text-[2.1rem] font-interMedium leading-[140%] md:leading-[150%]'>{props.heading}</p>
                    <p className='mt-5 md:mt-0 font-avantGardeBook text-[0.95rem] sm:text-[1.1rem] md:text-[1.2rem] lg:text-[1.3rem] xl:text-[1.4rem] 2xl:text-[1.5rem] md:w-2/4 leading-[140%]'>{props.desc}</p>
                </div>
            </div>
        </div>
        
    )
}

export default TextComponent