import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Homepage/Home";
import Sarvodaya from "./pages/Sarvodaya"
import VeenaVihar from "./pages/VeenaVihar";
import Tranquil from "./pages/Tranquil";
import Raiseaquote from "./components/Raiseaquote";
import AllProjects from "./pages/AllProjects";


function App() {
  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/projects/sarvodaya" element={<Sarvodaya />} />
          <Route path="/projects/veenavihar" element={<VeenaVihar />} />
          <Route path="/projects/tranquil" element={<Tranquil />} />
          <Route path="/raiseaquote" element={<Raiseaquote />} />
          <Route path="/all-projects" element={<AllProjects />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
