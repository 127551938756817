import React from 'react'
import { Navigation, Pagination, Scrollbar, A11y, EffectCreative } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from 'react-responsive';
import CustomCursor from '../components/customCursor';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-creative';
import HeroTranquil from '../components/projectsComponents/HeroTranquil'
import Navbar from '../components/Navbar'
import TextComponent from '../components/projectsComponents/TextComponent';
import birdeye1 from '../assets/images/projects/tranquil/08.jpg';
import sideview1 from '../assets/images/projects/tranquil/05.jpg';
import sideview2 from '../assets/images/projects/tranquil/04.jpg';
import sideview3 from '../assets/images/projects/tranquil/03.jpg';
import sideview4 from '../assets/images/projects/tranquil/06.jpg';
import common1 from '../assets/images/projects/tranquil/01.jpg';
import common2 from '../assets/images/projects/tranquil/02.jpg';
import Footer from '../components/Footer';

const Tranquil = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  return (
    <div className='bg-[#E6E9F4]'>
      {!isMobile && (
        <CustomCursor />
      )}
      <Navbar bgColor="black" headerColor="[#E6E9F4]"/>
      <HeroTranquil />

      {/* "BIRD EYE VIEW" */}
      <div className='birdeye'>
        <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
          <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Bird Eye View</p>
        </div>
        <div className=''>
          <Swiper
            // install Swiper modules
            modules={[Navigation, Scrollbar, A11y, EffectCreative, Pagination]}
            pagination={{ clickable: true }}
            spaceBetween={25}
            slidesPerView={1}
            navigation
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
            effect='creative'
            creativeEffect={{
              prev: {
                shadow: true,
                translate: ['-121%', 0, -500],
              },
              next: {
                shadow: true,
                translate: ['121%', 0, -500],
              },
            }}
          >
            <SwiperSlide><img src={birdeye1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>

          </Swiper>
        </div>
      </div>

      <TextComponent heading="The Shine of Your Lifestyle" desc="Tranquil is one of the most awaited and prestigious projects by Concicon Constructions which is situated at one of the best location of the Patna city. It is one of those strategically planned apartments which promise ceaseless comfort and convenience to people." />

      {/* "SIDE VIEW" */}
      <div className='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'>
        <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
          <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Side View</p>
        </div>
        <div className=''>
          <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative]}
            pagination={{ clickable: true }}
            spaceBetween={25}
            slidesPerView={1}
            navigation
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
            effect='creative'
            creativeEffect={{
              prev: {
                shadow: true,
                translate: ['-121%', 0, -500],
              },
              next: {
                shadow: true,
                translate: ['121%', 0, -500],
              },
            }}
          >
            <SwiperSlide><img src={sideview1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
            <SwiperSlide><img src={sideview2} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
            <SwiperSlide><img src={sideview3} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
            <SwiperSlide><img src={sideview4} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
          </Swiper>
        </div>
      </div>

      {/* "Common Area" */}
      <div className='common pb-20'>
        <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
          <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Common Area</p>
        </div>
        <div className=''>
          <Swiper
            // install Swiper modules
            modules={[Navigation, Scrollbar, A11y, EffectCreative, Pagination]}
            pagination={{ clickable: true }}
            spaceBetween={25}
            slidesPerView={1}
            navigation
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
            effect='creative'
            creativeEffect={{
              prev: {
                shadow: true,
                translate: ['-121%', 0, -500],
              },
              next: {
                shadow: true,
                translate: ['121%', 0, -500],
              },
            }}
          >
            <SwiperSlide><img src={common1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
            <SwiperSlide><img src={common2} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>

          </Swiper>
        </div>
      </div>

    </div>

  )
}

export default Tranquil