import React from 'react'

function Footer() {
  return (
    <section className='relative bg-black pb-4  lg:pt-24' data-scroll-section>
      {/* old footer */}

      {/* <hr className="border-t border-greyText border-opacity-50 px-8 md:px-16" />
      <div className="flex flex-col sm:flex-row items-center justify-between h-[25vh] sm:h-auto p-8 sm:p-16">
        <a href="/" className="text-white text-[1.1rem] md:text-[1.5rem]">Privacy Policy</a>
        <a href="/" className="text-white text-[1.1rem] md:text-[1.5rem]">Terms of Service</a>
        <a href="/" className="text-white text-[1.1rem] md:text-[1.5rem]">Projects</a>
        <a href="/" className="text-white text-[1.1rem] md:text-[1.5rem]">Raise a Quote</a>
      </div>

      <div className='flex flex-col sm:flex-row justify-between items-center md:items-end p-8 sm:p-16'>
        <div className='flex flex-col items-center sm:items-start'>
          <h2 className='text-white  font-interSemibold text-[1.3rem] sm:text-[2.5rem] md:text-[3rem] lg:text-[4rem]'>CONCICON</h2>
          <a href="/" className="text-white text-sm md:text-[1.2rem] lg:text-[1.18rem]"><span style={{ opacity: 0.75 }}>© 2010 Concicon. All rights reserved</span></a>
        </div>
        <div className="">
          <div>
            <a href="https://abhijeet.space" target='_blank' className="text-white text-sm md:text-[1.2rem] lg:text-[1.18rem]"><span style={{ opacity: 0.75 }}>Made with </span> 🤍 <span style={{ opacity: 0.75 }}>by</span> <strong>Abhijeet Anand</strong>
          </a>
          </div>
          
        </div>
      </div> */}



      {/* New Footer */}

      <div className=' bg-[#e8e0d5] p-6 md:p-8 lg:p-10 xl:p-12 mx-4 rounded-3xl font-interRegular md:font-interMedium text-[1rem] lg:text-[1.1rem] xl:text-[1.2rem] 2xl:text-[1.3rem] md:tracking-wide'>
        <div className='flex flex-col sm:flex-row justify-between gap-5'>
          <p className='font-interMedium md:font-interSemibold text-[1.7rem] lg:text-[2.2rem] pt-2 md:pt-0 tracking-wide'>Concicon</p>
          <p className=' sm:w-2/5 tracking-wide'>We construct beautiful buildings, craft tangible homes, and architect the landmark of future.</p>
          <div className=' flex flex-col sm:w-1/5 gap-1 lg:gap-2 xl:gap-3'>
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/terms-of-service">Terms of Service</a>
            <a href="#projects">Projects</a>
            <a href="/raiseaquote">Raise a Quote</a>
          </div>
        </div>

        <div className='pt-12 lg:pt-16 xl:pt-20'>
          <div className='flex justify-end gap-4 pb-3'>
            {/* social */}
            <a href="https://facebook.com" className='h-5 xl:h-7 w-5 xl:w-7'>
              <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 25 25" fill="none">
                <path d="M25 12.5C25 5.6 19.4 0 12.5 0C5.6 0 0 5.6 0 12.5C0 18.55 4.3 23.5875 10 24.75V16.25H7.5V12.5H10V9.375C10 6.9625 11.9625 5 14.375 5H17.5V8.75H15C14.3125 8.75 13.75 9.3125 13.75 10V12.5H17.5V16.25H13.75V24.9375C20.0625 24.3125 25 18.9875 25 12.5Z" fill="#191919" />
              </svg>
            </a>
            <a href="https://x.com" className='h-5 xl:h-7 w-5 xl:w-7'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 21" fill="none">
                <path d="M25 2.43408C24.0798 2.86004 23.088 3.13996 22.0602 3.27383C23.1119 2.6288 23.9245 1.60649 24.3069 0.377282C23.315 0.985801 22.2156 1.41176 21.0564 1.65517C20.1123 0.608519 18.7859 0 17.2801 0C14.4718 0 12.1773 2.33671 12.1773 5.2211C12.1773 5.63489 12.2251 6.03651 12.3088 6.41379C8.05449 6.19473 4.26625 4.11359 1.74474 0.96146C1.30258 1.72819 1.05163 2.6288 1.05163 3.57809C1.05163 5.39148 1.9479 6.99797 3.33413 7.91075C2.48566 7.91075 1.69694 7.66734 1.00382 7.30223V7.33874C1.00382 9.87018 2.77247 11.9878 5.11472 12.4625C4.36272 12.6721 3.57325 12.7012 2.80832 12.5477C3.1329 13.5852 3.76857 14.493 4.62598 15.1435C5.48339 15.7941 6.51942 16.1546 7.58843 16.1744C5.77633 17.6354 3.53011 18.4251 1.21893 18.4138C0.81262 18.4138 0.40631 18.3895 0 18.3408C2.27055 19.8256 4.97132 20.6897 7.86329 20.6897C17.2801 20.6897 22.4546 12.7302 22.4546 5.82961C22.4546 5.59838 22.4546 5.37931 22.4426 5.14807C23.4465 4.41785 24.3069 3.4929 25 2.43408Z" fill="#191919" />
              </svg>
            </a>
            <a href="https:instagram.com" className='h-5 xl:h-7 w-5 xl:w-7'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" fill="none">
                <path d="M7.25 0H17.75C21.75 0 25 3.25 25 7.25V17.75C25 19.6728 24.2362 21.5169 22.8765 22.8765C21.5169 24.2362 19.6728 25 17.75 25H7.25C3.25 25 0 21.75 0 17.75V7.25C0 5.32718 0.763837 3.48311 2.12348 2.12348C3.48311 0.763837 5.32718 0 7.25 0ZM7 2.5C5.80653 2.5 4.66193 2.97411 3.81802 3.81802C2.97411 4.66193 2.5 5.80653 2.5 7V18C2.5 20.4875 4.5125 22.5 7 22.5H18C19.1935 22.5 20.3381 22.0259 21.182 21.182C22.0259 20.3381 22.5 19.1935 22.5 18V7C22.5 4.5125 20.4875 2.5 18 2.5H7ZM19.0625 4.375C19.4769 4.375 19.8743 4.53962 20.1674 4.83265C20.4604 5.12567 20.625 5.5231 20.625 5.9375C20.625 6.3519 20.4604 6.74933 20.1674 7.04235C19.8743 7.33538 19.4769 7.5 19.0625 7.5C18.6481 7.5 18.2507 7.33538 17.9576 7.04235C17.6646 6.74933 17.5 6.3519 17.5 5.9375C17.5 5.5231 17.6646 5.12567 17.9576 4.83265C18.2507 4.53962 18.6481 4.375 19.0625 4.375ZM12.5 6.25C14.1576 6.25 15.7473 6.90848 16.9194 8.08058C18.0915 9.25268 18.75 10.8424 18.75 12.5C18.75 14.1576 18.0915 15.7473 16.9194 16.9194C15.7473 18.0915 14.1576 18.75 12.5 18.75C10.8424 18.75 9.25268 18.0915 8.08058 16.9194C6.90848 15.7473 6.25 14.1576 6.25 12.5C6.25 10.8424 6.90848 9.25268 8.08058 8.08058C9.25268 6.90848 10.8424 6.25 12.5 6.25ZM12.5 8.75C11.5054 8.75 10.5516 9.14509 9.84835 9.84835C9.14509 10.5516 8.75 11.5054 8.75 12.5C8.75 13.4946 9.14509 14.4484 9.84835 15.1517C10.5516 15.8549 11.5054 16.25 12.5 16.25C13.4946 16.25 14.4484 15.8549 15.1517 15.1517C15.8549 14.4484 16.25 13.4946 16.25 12.5C16.25 11.5054 15.8549 10.5516 15.1517 9.84835C14.4484 9.14509 13.4946 8.75 12.5 8.75Z" fill="#191919" />
              </svg>
            </a>
          </div>
          <div className='h-[1px] bg-[#BABABA] mb-6'>
            {/* line */}
          </div>
          <div className='flex flex-col sm:flex-row gap-1 sm:gap-0 justify-between text-[0.9rem] lg:text-[1.1rem] xl:text-[1.2rem] 2xl:text-[1.3rem]'>
            <p>© 2010 Concicon Construction Pvt. Ltd.</p>
            <div>
              <a href="https://abhijeet.space" target='_blank' rel="noreferrer" ><span style={{ opacity: 0.75 }}>Made with </span> <span className=' invert'>🤍</span> by <strong>Abhijeet A.</strong></a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer