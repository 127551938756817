import { useRef } from "react";
import Hero from "../../components/Hero";
import Navbar from "../../components/Navbar";
import About from "../../components/About";
import Projects from "../../components/Projects";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import Testimonial from "../../components/Testimonial";
import CustomCursor from "../../components/customCursor";
import useLocoScroll from "../../hooks/useLocoScroll";
import { useMediaQuery } from "react-responsive";
import Gallery from "../../components/Gallery"

import './home.scss'

function Home() {
  const ref = useRef(null);
  useLocoScroll(false)
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  return (
    <>
      {!isMobile && (
        <CustomCursor />
      )}

      <div
        className="main-container"
        id="main-container"
        data-scroll-container
        ref={ref}
      >

        <Navbar bgColor="lightBeige" headerColor="white" />
        <Hero />
        <About />
        {!isMobile && (
          <Gallery />
        )}
        {isMobile && (
          <Projects />
        )}

        <Testimonial />
        <Contact />
        <Footer />
      </div>
    </>

  );
}

export default Home;
