import React from 'react'
import FeaturedImg from '../assets/images/featured-mobile.png';
import Featured2 from '../assets/images/featured2.jpeg';
import { useMediaQuery } from 'react-responsive';
import shoppersMall from '../assets/images/shoppers.jpeg';
import tranquil from '../assets/images/tranquil.jpeg';
import veenaVihar from '../assets/images/veenanight.png'
import enterance from '../assets/images/projects/sarvodaya/enterance1.jpeg'

function Projects() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  return (
    <section data-scroll-section id='projects' className='bg-white '>
      {/* Top Heading */}
      <div className='flex flex-col justify-center items-center pt-8 pb-4'>
        <p className='font-interSemiBold text-base lg:text-xl text-darkText'>FEATURED</p>
        <div className='flex lg:p-3'>
          <p className='font-interSemiBold text-[2.4rem] lg:text-[4.5rem] text-darkText'>PROJECTS</p>
        </div>
        <a href="/all-projects">
          <button
            type="submit"
            className="bg-black text-white text-sm lg:text-lg py-3 px-7 lg:px-10  rounded-full flex items-center font-interSemibold mt-5"
          >
            All Projects
            <svg
              class="w-3.5 h-3.5 ml-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </button>
        </a>

      </div>
      {/* on mobile */}
      {isMobile && (
        <div className='flex flex-col justify-center items-center '>

          <div className=' flex flex-col justify-center items-center'>
            <div className='w-screen flex flex-col justify-center items-center relative'>
              <img src={FeaturedImg} alt="FeaturedImage" className=' ' />
              <a href='/projects/sarvodaya' className='w-[4.5rem] h-[4.5rem] sm:w-[7.4rem] sm:h-[7.4rem] md:w-[8rem] md:h-[8rem] lg:w-[9.2rem] lg:h-[9.2rem] 2xl:w-[10rem] 2xl:h-[10rem]  rounded-full bg-[#AAAA8DA6] opacity-65 background-blur flex flex-col justify-center items-center'>
                <div className='w-[1.2rem] h-[1.2rem] sm:w-[1.9rem] sm:h-[1.9rem] md:w-[1.8rem] md:h-[1.8rem] lg:w-[1.9rem] lg:h-[1.9rem] 2xl:w-[2.2rem] 2xl:h-[2.2rem]'>
                  <svg viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M41.3923 1.35257C41.7318 1.69209 41.9961 2.06438 42.1852 2.46943C42.3743 2.87448 42.4685 3.33201 42.4678 3.84201L42.3069 37.6153C42.302 38.6387 41.9465 39.5035 41.2404 40.2096C40.5343 40.9157 39.6687 41.272 38.6435 41.2786C37.6201 41.2835 36.7688 40.9464 36.0898 40.2674C35.4108 39.5883 35.0737 38.7371 35.0786 37.7137L35.198 12.6396L6.40541 41.4322C5.67702 42.1606 4.83272 42.516 3.87251 42.4984C2.91229 42.4808 2.0723 42.1121 1.35252 41.3923C0.63104 40.6708 0.271785 39.8197 0.274752 38.8389C0.277719 37.8581 0.6451 37.0036 1.3769 36.2752L30.1052 7.5468L5.03117 7.66626C4.00773 7.67114 3.1565 7.33406 2.47746 6.65502C1.79842 5.97598 1.46135 5.12475 1.46622 4.10132C1.4711 3.07789 1.82744 2.21226 2.53526 1.50444C3.24308 0.796624 4.10785 0.441133 5.12957 0.437971L38.9028 0.277064C39.4145 0.274626 39.8729 0.367959 40.278 0.557065C40.683 0.746172 41.0545 1.01134 41.3923 1.35257Z" fill="black" />
                  </svg>
                </div>
                
              </a>
              <div className=' flex flex-col items-center'>
                <p className='font-interRegular text-darkText text-[1.75rem] xl:text-4xl 2xl:text-5xl mt-3'>Sarvodaya City</p>
                <p className='uppercase font-montserratMedium text-darkText text-[1rem] xl:text-2xl tracking-tight'>Residential</p>
                
              </div>
            </div>

          </div>

          <div className='w-11/12 flex flex-col justify-center items-center mt-6'>
            <div className='flex flex-col justify-center items-center'>
              <p className='text-black text-[0.9rem]  xl:text-[1.25rem] 2xl:text-[1.3rem] uppercase font-interMedium text-center tracking-tighter '>
                Sarvodaya City is a testament to our commitment as a construction firm
                to create structures that are not just buildings, but a holistic living experience.
              </p>
              
            </div>
          </div>
          {/* <p className='text-sm lg:text-base text-black font-interMedium w-10/12 leading-snug lg:mt-28 text-center border-t-2 border-borderColor pt-7 mt-5'>Our team diligently constructed every part of the building with precision,
            using top-notch materials and innovative techniques for its durability and longevity.
          </p> */}
        </div>
      )}



      {!isMobile && (
        <div className='flex'>
          {/* Left Section */}
          <div className='w-1/2 border-r-2 border-darkText flex flex-col justify-center items-center'>
            <div className='flex flex-col justify-center items-center'>
              <p className='text-white text-[0.9rem] md:text-[1rem] lg:text-[1.1rem] xl:text-[1.25rem] 2xl:text-[1.3rem] uppercase font-interRegular text-center w-5/6'>Sarvodaya City is a testament to our commitment as a construction firm
                to create structures that are not just buildings, but a holistic living experience.
              </p>
              <a href="/"><p className='text-white underline font-interSemibold text-[1.1rem] mt-4'>View Details</p></a>
            </div>
          </div>
          {/* Right Section */}
          <div className='w-1/2 flex flex-col justify-center items-center'>
            <div className='w-5/6 flex flex-col justify-center items-center relative'>
              <img src={FeaturedImg} alt="FeaturedImage" className='h-[30vh] sm:h-[40vh] md:h-[55vh] lg:h-[63vh] xl:h-[65vh] 2xl:h-full' />
              <a href='/projects/sarvodaya' className='w-[6.8rem] h-[6.8rem] sm:w-[7.4rem] sm:h-[7.4rem] md:w-[8rem] md:h-[8rem] lg:w-[9.2rem] lg:h-[9.2rem] 2xl:w-[10rem] 2xl:h-[10rem] absolute top-[55%] rounded-full bg-[#AAAA8DA6] opacity-65 background-blur flex flex-col justify-center items-center'>
                <div className='w-[1.4rem] h-[1.4rem] sm:w-[1.6rem] sm:h-[1.6rem] md:w-[1.8rem] md:h-[1.8rem] lg:w-[1.9rem] lg:h-[1.9rem] 2xl:w-[2.2rem] 2xl:h-[2.2rem]'>
                  <svg viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M41.3923 1.35257C41.7318 1.69209 41.9961 2.06438 42.1852 2.46943C42.3743 2.87448 42.4685 3.33201 42.4678 3.84201L42.3069 37.6153C42.302 38.6387 41.9465 39.5035 41.2404 40.2096C40.5343 40.9157 39.6687 41.272 38.6435 41.2786C37.6201 41.2835 36.7688 40.9464 36.0898 40.2674C35.4108 39.5883 35.0737 38.7371 35.0786 37.7137L35.198 12.6396L6.40541 41.4322C5.67702 42.1606 4.83272 42.516 3.87251 42.4984C2.91229 42.4808 2.0723 42.1121 1.35252 41.3923C0.63104 40.6708 0.271785 39.8197 0.274752 38.8389C0.277719 37.8581 0.6451 37.0036 1.3769 36.2752L30.1052 7.5468L5.03117 7.66626C4.00773 7.67114 3.1565 7.33406 2.47746 6.65502C1.79842 5.97598 1.46135 5.12475 1.46622 4.10132C1.4711 3.07789 1.82744 2.21226 2.53526 1.50444C3.24308 0.796624 4.10785 0.441133 5.12957 0.437971L38.9028 0.277064C39.4145 0.274626 39.8729 0.367959 40.278 0.557065C40.683 0.746172 41.0545 1.01134 41.3923 1.35257Z" fill="black" />
                  </svg>
                </div>
                <p className='text-interRegular text-sm md:text-xl pt-1 lg:pt-4'>View</p>
              </a>
              <div className='absolute top-[93%] flex flex-col items-center'>
                <p className='uppercase font-interSemibold text-white text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl'>Sarvodaya</p>
                <p className='uppercase font-interSemibold text-white text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl'>City</p>
              </div>
            </div>
            <p className='text-xs lg:text-base text-greyText font-interMedium w-10/12 mt-20 lg:mt-28 text-center border-t-2 border-borderColor pt-5'>Our team diligently constructed every part of the building with precision,
              using top-notch materials and innovative techniques for its durability and longevity.
            </p>
          </div>
        </div>
      )}

      {/* Grid */}
      <div className='grid grid-flow-row md:grid-cols-3 gap-7 mt-20 mx-6 sm:mx-8 lg:mx-12 xl:mx-16 pb-16'>
        <div className='flex justify-center h-[25rem] sm:h-[18rem] md:h-[21rem] lg:h-[25rem] xl:h-[30rem] 2xl:h-[33.75rem] overflow-clip rounded-2xl relative'>
          <img src={shoppersMall} alt="" className='h-full object-cover opacity-100' />
          <p className='absolute top-[65%] sm:top-[73%] md:top-[70%] lg:top-[65%] text-white font-interMedium tracking-tight text-2xl md:text-2xl lg:text-3xl uppercase'>Shoppers Mall</p>
          <button className='w-[6.8rem] h-[6.8rem] sm:w-[7.4rem] sm:h-[7.4rem] md:w-[8rem] md:h-[8rem] lg:w-[9.2rem] lg:h-[9.2rem] 2xl:w-[10rem] 2xl:h-[10rem] absolute top-[27%] rounded-full bg-[#AAAA8DA6] opacity-65 background-blur flex flex-col justify-center items-center'>
            <div className='w-[1.4rem] h-[1.4rem] sm:w-[1.6rem] sm:h-[1.6rem] md:w-[1.8rem] md:h-[1.8rem] lg:w-[1.9rem] lg:h-[1.9rem] 2xl:w-[2.2rem] 2xl:h-[2.2rem]'>
              <svg viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M41.3923 1.35257C41.7318 1.69209 41.9961 2.06438 42.1852 2.46943C42.3743 2.87448 42.4685 3.33201 42.4678 3.84201L42.3069 37.6153C42.302 38.6387 41.9465 39.5035 41.2404 40.2096C40.5343 40.9157 39.6687 41.272 38.6435 41.2786C37.6201 41.2835 36.7688 40.9464 36.0898 40.2674C35.4108 39.5883 35.0737 38.7371 35.0786 37.7137L35.198 12.6396L6.40541 41.4322C5.67702 42.1606 4.83272 42.516 3.87251 42.4984C2.91229 42.4808 2.0723 42.1121 1.35252 41.3923C0.63104 40.6708 0.271785 39.8197 0.274752 38.8389C0.277719 37.8581 0.6451 37.0036 1.3769 36.2752L30.1052 7.5468L5.03117 7.66626C4.00773 7.67114 3.1565 7.33406 2.47746 6.65502C1.79842 5.97598 1.46135 5.12475 1.46622 4.10132C1.4711 3.07789 1.82744 2.21226 2.53526 1.50444C3.24308 0.796624 4.10785 0.441133 5.12957 0.437971L38.9028 0.277064C39.4145 0.274626 39.8729 0.367959 40.278 0.557065C40.683 0.746172 41.0545 1.01134 41.3923 1.35257Z" fill="black" />
              </svg>
            </div>
            <p className='text-interRegular text-sm md:text-xl pt-1 lg:pt-4'>View</p>
          </button>
        </div>
        <div className='h-[25rem] sm:h-[18rem] md:h-[21rem] lg:h-[25rem] xl:h-[30rem] 2xl:h-[33.75rem] overflow-clip rounded-2xl flex justify-center relative'>
          <img src={tranquil} alt="" className='h-full object-cover opacity-100' />
          <p className='absolute top-[65%] sm:top-[73%] md:top-[70%] lg:top-[65%] text-white font-interMedium tracking-tight text-2xl md:text-2xl lg:text-3xl uppercase'>Tranquil</p>
          <a href='/projects/tranquil' className='w-[6.8rem] h-[6.8rem] sm:w-[7.4rem] sm:h-[7.4rem] md:w-[8rem] md:h-[8rem] lg:w-[9.2rem] lg:h-[9.2rem] 2xl:w-[10rem] 2xl:h-[10rem] absolute top-[27%] rounded-full bg-[#AAAA8DA6] opacity-65 background-blur flex flex-col justify-center items-center'>
            <div className='w-[1.4rem] h-[1.4rem] sm:w-[1.6rem] sm:h-[1.6rem] md:w-[1.8rem] md:h-[1.8rem] lg:w-[1.9rem] lg:h-[1.9rem] 2xl:w-[2.2rem] 2xl:h-[2.2rem]'>
              <svg viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M41.3923 1.35257C41.7318 1.69209 41.9961 2.06438 42.1852 2.46943C42.3743 2.87448 42.4685 3.33201 42.4678 3.84201L42.3069 37.6153C42.302 38.6387 41.9465 39.5035 41.2404 40.2096C40.5343 40.9157 39.6687 41.272 38.6435 41.2786C37.6201 41.2835 36.7688 40.9464 36.0898 40.2674C35.4108 39.5883 35.0737 38.7371 35.0786 37.7137L35.198 12.6396L6.40541 41.4322C5.67702 42.1606 4.83272 42.516 3.87251 42.4984C2.91229 42.4808 2.0723 42.1121 1.35252 41.3923C0.63104 40.6708 0.271785 39.8197 0.274752 38.8389C0.277719 37.8581 0.6451 37.0036 1.3769 36.2752L30.1052 7.5468L5.03117 7.66626C4.00773 7.67114 3.1565 7.33406 2.47746 6.65502C1.79842 5.97598 1.46135 5.12475 1.46622 4.10132C1.4711 3.07789 1.82744 2.21226 2.53526 1.50444C3.24308 0.796624 4.10785 0.441133 5.12957 0.437971L38.9028 0.277064C39.4145 0.274626 39.8729 0.367959 40.278 0.557065C40.683 0.746172 41.0545 1.01134 41.3923 1.35257Z" fill="black" />
              </svg>
            </div>
            <p className='text-interRegular text-sm md:text-xl pt-1 lg:pt-4'>View</p>
          </a>
        </div>
        <div className='h-[25rem] sm:h-[18rem] md:h-[21rem] lg:h-[25rem] xl:h-[30rem] 2xl:h-[33.75rem] overflow-clip rounded-2xl flex justify-center relative'>
          <img src={veenaVihar} alt="" className='h-full object-cover opacity-100' />
          <p className='absolute top-[65%] sm:top-[73%] md:top-[70%] lg:top-[65%] text-white font-interMedium tracking-tight text-2xl md:text-2xl lg:text-3xl uppercase'>Veena Vihar</p>
          <a href='/projects/veenavihar' className='w-[6.8rem] h-[6.8rem] sm:w-[7.4rem] sm:h-[7.4rem] md:w-[8rem] md:h-[8rem] lg:w-[9.2rem] lg:h-[9.2rem] 2xl:w-[10rem] 2xl:h-[10rem] absolute top-[27%] rounded-full bg-[#AAAA8DA6] opacity-65 background-blur flex flex-col justify-center items-center'>
            <div className='w-[1.4rem] h-[1.4rem] sm:w-[1.6rem] sm:h-[1.6rem] md:w-[1.8rem] md:h-[1.8rem] lg:w-[1.9rem] lg:h-[1.9rem] 2xl:w-[2.2rem] 2xl:h-[2.2rem]'>
              <svg viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M41.3923 1.35257C41.7318 1.69209 41.9961 2.06438 42.1852 2.46943C42.3743 2.87448 42.4685 3.33201 42.4678 3.84201L42.3069 37.6153C42.302 38.6387 41.9465 39.5035 41.2404 40.2096C40.5343 40.9157 39.6687 41.272 38.6435 41.2786C37.6201 41.2835 36.7688 40.9464 36.0898 40.2674C35.4108 39.5883 35.0737 38.7371 35.0786 37.7137L35.198 12.6396L6.40541 41.4322C5.67702 42.1606 4.83272 42.516 3.87251 42.4984C2.91229 42.4808 2.0723 42.1121 1.35252 41.3923C0.63104 40.6708 0.271785 39.8197 0.274752 38.8389C0.277719 37.8581 0.6451 37.0036 1.3769 36.2752L30.1052 7.5468L5.03117 7.66626C4.00773 7.67114 3.1565 7.33406 2.47746 6.65502C1.79842 5.97598 1.46135 5.12475 1.46622 4.10132C1.4711 3.07789 1.82744 2.21226 2.53526 1.50444C3.24308 0.796624 4.10785 0.441133 5.12957 0.437971L38.9028 0.277064C39.4145 0.274626 39.8729 0.367959 40.278 0.557065C40.683 0.746172 41.0545 1.01134 41.3923 1.35257Z" fill="black" />
              </svg>
            </div>
            <p className='text-interRegular text-sm md:text-xl pt-1 lg:pt-4'>View</p>
          </a>
        </div>
        <div className='h-[25rem] md:h-auto md:col-span-3  overflow-clip rounded-2xl flex justify-center relative'>
          <img src={enterance} alt="" className='h-full object-cover opacity-100 flex justify-center relative' />
          <p className='absolute top-[65%] sm:top-[73%] md:top-[70%] lg:top-[60%] text-white font-interMedium tracking-tight text-2xl md:text-2xl lg:text-3xl uppercase'>Sarvodaya City</p>
          <a href='/projects/sarvodaya' className='w-[6.8rem] h-[6.8rem] sm:w-[7.4rem] sm:h-[7.4rem] md:w-[8rem] md:h-[8rem] lg:w-[9.2rem] lg:h-[9.2rem] 2xl:w-[10rem] 2xl:h-[10rem] absolute top-[27%] rounded-full bg-[#AAAA8DA6] opacity-65 background-blur flex flex-col justify-center items-center'>
            <div className='w-[1.4rem] h-[1.4rem] sm:w-[1.6rem] sm:h-[1.6rem] md:w-[1.8rem] md:h-[1.8rem] lg:w-[1.9rem] lg:h-[1.9rem] 2xl:w-[2.2rem] 2xl:h-[2.2rem]'>
              <svg viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M41.3923 1.35257C41.7318 1.69209 41.9961 2.06438 42.1852 2.46943C42.3743 2.87448 42.4685 3.33201 42.4678 3.84201L42.3069 37.6153C42.302 38.6387 41.9465 39.5035 41.2404 40.2096C40.5343 40.9157 39.6687 41.272 38.6435 41.2786C37.6201 41.2835 36.7688 40.9464 36.0898 40.2674C35.4108 39.5883 35.0737 38.7371 35.0786 37.7137L35.198 12.6396L6.40541 41.4322C5.67702 42.1606 4.83272 42.516 3.87251 42.4984C2.91229 42.4808 2.0723 42.1121 1.35252 41.3923C0.63104 40.6708 0.271785 39.8197 0.274752 38.8389C0.277719 37.8581 0.6451 37.0036 1.3769 36.2752L30.1052 7.5468L5.03117 7.66626C4.00773 7.67114 3.1565 7.33406 2.47746 6.65502C1.79842 5.97598 1.46135 5.12475 1.46622 4.10132C1.4711 3.07789 1.82744 2.21226 2.53526 1.50444C3.24308 0.796624 4.10785 0.441133 5.12957 0.437971L38.9028 0.277064C39.4145 0.274626 39.8729 0.367959 40.278 0.557065C40.683 0.746172 41.0545 1.01134 41.3923 1.35257Z" fill="black" />
              </svg>
            </div>
            <p className='text-interRegular text-sm md:text-xl pt-1 lg:pt-4'>View</p>
          </a>
        </div>
      </div>
    </section>
  )
}

export default Projects