import React from 'react'
import Navbar from '../Navbar'
import { useMediaQuery } from 'react-responsive';

import "./styles.scss"
import CustomCursor from '../customCursor'

const Raiseaquote = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    return (
        <div className='bg-white'>
            {!isMobile && (
                <CustomCursor />
            )}
            <Navbar bgColor="lightBeige" headerColor="white" />
            <div className='px-5 sm:px-16 md:px-24 xl:px-32 pt-28 md:pt-32'>
                <div className='flex items-center border-b border-[#b6b6b6] pb-5'>
                    <div className=' font-interMedium flex flex-col gap-1'>
                        <p className='text-[1.8rem] md:text-[2.2rem] lg:text-[2.5rem] xl:text-[3rem]'>Raise a Quote</p>
                        <p className=' font-interRegular text-[0.9rem] md:text-[1rem] lg:text-[1.15rem] xl:text-[1.25rem] w-11/12 md:w-9/12 xl:w-9/12 2xl:w-7/12'>Get started by providing some details and we'll connect you with qualified
                            agents who can offer personalized quotes</p>
                    </div>
                    <div className='w-5/12 md:w-5/12 lg:w-4/12 xl:w-3/12 2xl:w-1/6'>
                        <p className=' whitespace-nowrap font-montserratMedium  text-[2.5rem] md:text-[4rem] lg:text-[6rem] xl:text-[7.5rem]'>“ : )</p>
                    </div>
                </div>

                {/* form */}
                <div className='font-interMedium pt-10 text-[1.25rem] md:text-[1.35rem] lg:text-[1.5rem]'>
                    <div className='text-[1.8rem] md:text-[2.2rem] lg:text-[2.5rem] xl:text-[3rem] pb-8 leading-tight'> 
                        <p>hello, </p>
                        <p>Concicon!</p>
                    </div>


                    <form action="/raiseaquote" className=''>
                        <div className='flex flex-col md:flex-row gap-3 md:gap-0'>
                            <label htmlFor="name" className=' whitespace-nowrap pr-5'>My Name is</label>
                            <input className='inline border-b border-black w-full text-black leading-tight focus:outline-none py-3 text-[1rem] md:text-[1.2rem] rounded-none' type="text" id='name' placeholder='Your Name' />
                            <label htmlFor="address" className=' whitespace-nowrap pr-5'>, I am from</label>
                            <input className='inline border-b border-black w-full text-black leading-tight focus:outline-none py-3 text-[1rem] md:text-[1.2rem] rounded-none' type="text" id='address' placeholder='Your Address' />
                        </div>

                        <label htmlFor="" className=' block pr-5 py-6'>I would like to discuss about</label>
                        <div className='flex items-center pb-0 md:pb-2'>
                            <input type="checkbox" id="apartments" name="Apartments" className='' />
                            <label for="apartments" className='pl-5 text-borderColor py-3 text-[1.1rem] md:text-[1.2rem]'>Apartments</label>
                        </div>

                        <div className='flex items-center pb-0 md:pb-2'>
                            <input type="checkbox" id="rentals-residential" name="rentals-residential" />
                            <label for="rentals-residential" className='pl-5 text-borderColor py-3 text-[1.1rem] md:text-[1.2rem]'>Rentals (Residential)</label>
                        </div>

                        <div className='flex items-center'>
                            <input type="checkbox" id="rentals-residential" name="rentals-commercial" />
                            <label for="rentals-commercial" className='pl-5 text-borderColor py-3 text-[1.1rem] md:text-[1.2rem]'>Rentals (Commercial)</label>
                        </div>

                        <label htmlFor="questions" className=' block pr-5 pt-8 pb-4'>I would like to write</label>
                        <textarea id="questions" name="questions" rows="4" cols="" className='w-full md:w-9/12 inline border-b border-black text-black leading-tight focus:outline-none py-3 text-[1rem] md:text-[1.2rem] rounded-none' placeholder='Questions you want to ask'>
                        </textarea>

                        <div className='w-full md:w-9/12'>
                            <div className='flex flex-col md:flex-row gap-3 pt-10'>
                                <label htmlFor="preffered-location" className=' whitespace-nowrap pr-5'>My Preffered location is</label>
                                <input className='inline border-b border-black w-full text-black leading-tight focus:outline-none py-3 text-[1rem] md:text-[1.2rem] rounded-none' type="text" id='preffered-location' placeholder='Enter Location' />
                            </div>

                            <div className='flex flex-col md:flex-row gap-3 pt-8'>
                                <label htmlFor="budget" className=' whitespace-nowrap pr-5'>My Budget is</label>
                                <input className='inline border-b border-black w-full text-black leading-tight focus:outline-none py-3 text-[1rem] md:text-[1.2rem] rounded-none' type="text" id='budget' placeholder='Enter Budget in rupees' />
                            </div>

                            <div className='flex flex-col md:flex-row gap-3 pt-8'>
                                <label htmlFor="email" className=' whitespace-nowrap pr-5'>My Email is</label>
                                <input className='inline border-b border-black w-full text-black leading-tight focus:outline-none py-3 text-[1rem] md:text-[1.2rem] rounded-none' type="text" id='email' placeholder='Enter Email' />
                            </div>

                            <div className='flex flex-col md:flex-row gap-3 pt-8'>
                                <label htmlFor="phone" className=' whitespace-nowrap pr-5'>You can reach me on</label>
                                <input className='inline border-b border-black w-full text-black leading-tight focus:outline-none py-3 text-[1rem] md:text-[1.2rem] rounded-none' type="text" id='phone' placeholder='Your Phone Number' />
                            </div>
                        </div>


                        <div className="flex justify-center py-16">
                            <button
                                type="submit"
                                className="bg-black text-white text-base md:text-lg py-3 px-10 rounded-full flex items-center font-interMedium"
                            >
                                Submit
                                <svg
                                    class="w-3 h-3 md:w-3.5 md:h-3.5 ml-2"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 10"
                                >
                                    <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M1 5h12m0 0L9 1m4 4L9 9"
                                    />
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    )
}

export default Raiseaquote