import React from 'react'

const ImageComponent = (props) => {
    let imagesrc = `../..${props.src}`
    
    return (
        <div className={`${props.margin}`}>
            <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                <p className='font-interMedium text-[0.75rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>{props.name}</p>
            </div>
            <img src={imagesrc} alt="HeroImg" className="w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]" />
        </div>
    )
}

export default ImageComponent