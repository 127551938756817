import React from "react";
import instagramImg from "../assets/images/instagram.png";
import facebookImg from "../assets/images/facebook.png";
import twitterImg from "../assets/images/twitter.png";
import "./style.css";

function Contact() {
  return (
    <section id="contact" className="bg-black pb-12 pt-16 md:pt-24" data-scroll-section>
      <div className="flex flex-col justify-center items-center pb-12 md:pb-16">
        <div className="flex flex-col p-3 gap-4 self-start ml-4 md:ml-8 lg:ml-24">
          <p className="font-interSemiBold text-[2.5rem] md:text-6xl text-white underline underline-offset-[12px] md:underline-offset-[16px] pb-0 md:pb-3">
            Contact Us
          </p>
          <p className="font-interRegular text-white text-lg text md:text-xl mr-12 sm:mr-0">To get a quote // To start a project // To ask any question you have</p>
        </div>
      </div>
      <div className="flex flex-col-reverse items-start md:flex-row px-7 lg:px-24">
        {/* left part of the contact page*/}
        <div className="w-full p-4 md:w-2/5 mt-16 md:mt-0">
          <div className="mb-2 sm:mb-3 lg:mb-4">
            <h2 className="text-3xl text-white font-interSemibold">
              Contact Details
            </h2>
          </div>
          <div className="mb-4 text-0.4xl pt-2 text-white text-opacity-75 font-interRegular">
            <p>+91 92349 57396</p>
            <p>info@concicon.com</p>
          </div>
          <div className="mb-4">
            <h2 className="text-3xl pt-10 text-white text-opacity-75 font-interSemibold">
              Other Socials
            </h2>
          </div>
          <div className="mb-4 pb-3 pt-4 text-0.4xl text-white text-opacity-75 font-interRegular">
            <div className="flex pb-3 items-center ">
              <img src={facebookImg} alt="Facebook" className="h-6 w-6 mr-2" />
              <p> Facebook </p>
            </div>
            <div className="flex pb-3 items-center">
              <img src={twitterImg} alt="Twitter" className="h-5 w-6 mr-2" />
              <p>Twitter</p>
            </div>
            <div className="flex pb-3 items-center">
              <img
                src={instagramImg}
                alt="Instagram"
                className="h-6 w-6 mr-2"
              />
              <p>Instagram</p>
            </div>
            <div className="mb-4 pt-10">
              <h2 className="text-3xl text-white font-interSemibold">
                Location
              </h2>
            </div>
            <div className="mb-4 text-0.5xl text-white text-opacity-75 font-interRegular pr-12 sm:pr-36 md:pr-16 lg:pr-32">
              <a href="https://www.google.com/search?client=ms-android-samsung-gs-rev1&sca_esv=5bdde8b43c3acd18&hl=en-IN&cs=0&sxsrf=ACQVn0-FKI5sT6Ud9c8sjLGU0noku1c-Fw%3A1708971863701&q=Birat%20Complex&ludocid=9465137265371633094&ibp=gwp%3B0%2C7&lsig=AB86z5WyHqnP-IxGqfXoasqg_MYq&kgs=25db80f6004fdee5&shndl=-1&shem=lcsnce%2Clsp&source=sh%2Fx%2Floc%2Fact%2Fm1%2F4">
                3rd floot Birat Complex, Boring Rd, Anandpuri, Patna, Bihar 800013
              </a>
            </div>
          </div>
        </div>

        {/* Right Part: Contact Form */}
        <div className="w-full md:w-3/5">
          <h2 className="text-2xl md:text-4xl lg:text-[2.2rem] font-interSemibold text-white pb-1 md:pb-2">
            Have something to ask?
          </h2>
          <h2 className="text-2xl md:text-4xl lg:text-[2.2rem] font-interSemibold  text-white pb-4 md:pb-3">
            Let’s answer them.
          </h2>
          <h4 className="text-[1.1rem] sm:text-[1.2rem] lg:text-[1.3rem] font-interRegular text-white text-opacity-75 pb-5">
            <p>To discuss a project
              or for more information</p>
          </h4>

          <form class="">
            <div class="flex flex-col md:flex-row items-start justify-between pb-20 pt-5">
              <div class="w-full">
                <div class="flex border-b white py-2 md:pr-3">
                  <input class="appearance-none bg-black border-b border-white w-full text-white py-2 leading-tight focus:outline-none rounded-none" type="text" placeholder="Your Name" />
                </div>
              </div>

              <div class=" lg:pl-10 w-full md:pl-3">
                <div class="flex border-b white py-2">
                  <input class="appearance-none bg-black border-b border-white w-full lg:w-full text-white py-2 leading-tight focus:outline-none rounded-none" type="text" placeholder="Your Email" />
                </div>
              </div>
            </div>
            <div className="flex items-center pb-12">
              <textarea class="appearance-none bg-black border-b border-white w-full text-white pb-20 leading-tight focus:outline-none rounded-none" placeholder="Your Message"></textarea>

            </div>

            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-white text-black text-sm py-3 px-4 pl-8 pr-8 rounded-full flex items-center font-interMedium"
              >
                Send Message
                <svg
                  class="w-3.5 h-3.5 ml-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;
