// import React from "react";
//import logo from "../assets/images/logo.png";
import { useMediaQuery } from 'react-responsive'

import Menu from "../Menu";
import Magnetic from "../Magnetic";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { opacity } from "../../utils/OpacityAnimation";
import { AnimatePresence } from "framer-motion";

import "./style.scss";
import { useScroll } from './useScroll';

export default function Navbar({ bgColor, headerColor }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const { scrollDirection } = useScroll();  

  const styles = {
    active: {
      visibility: "visible",
      transition: "all 0.5s"
    },
    hidden: {
      visibility: "hidden",
      transition: "all 0.5s",
      transform: "translateY(-100%)"
    }
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    let header = document.querySelector('header')
    if (!menuOpen) {
      header.classList.remove(`bg-${headerColor}`)
      header.classList.add(`bg-none`)
    }else {
      header.classList.remove(`bg-none`)
      header.classList.add(`bg-${headerColor}`)
    }
  };

  // useEffect(() => {
  //   if (menuOpen) {
  //     let header = document.querySelector('header')
      
  //     header.classList.remove(`bg-${headerColor}`)
  //     header.classList.add(`bg-none`)
 
  //   }
  // }, [])

  useEffect(() => {
    let navbar = document.querySelector('#navbar')
    let header = document.querySelector('header')
    window.addEventListener('scroll', () => {
      window.scrollY > 100
        ? navbar.classList.add('navbar-small')
        : navbar.classList.remove('navbar-small')
      
        window.scrollY > 100 && !menuOpen
        ? header.classList.add(`bg-${headerColor}`)
        : header.classList.remove(`bg-${headerColor}`)


    })
  }, [headerColor, menuOpen]);

  return (
    <div className="" >
      <AnimatePresence mode="wait">
        {menuOpen && (
          <motion.div variants={opacity} initial="initial" animate="openblur" exit="closed" className="absolute opacity-50 top-0 z-10 h-[11200px] w-screen bg-[#000]"></motion.div>
        )}
      </AnimatePresence>
      <header className={`fixed w-full top-0 z-30  transition-all duration-300 ease-linear`} style={{ ...styles.active, ...(scrollDirection === 'up' && window.scrollY > 400 ? { ...styles.hidden } : {}) }}>
        <div className="w-full max-w-8xl mx-auto px-2 lg:px-0 py-3 " >
          <nav className="flex justify-between place-items-center w-full" >

            <div id='navbar' className="flex items-center justify-between p-3 sm:px-5 mx-2 lg:mx-4 w-full" >
              <div className="flex items-baseline">
                {/* <img className="h-12 lg:h-16" src={logo} alt="logo"></img> */}
                <a href="/">
                  <p className="nav-text text-[1.4rem] sm:text-[1.8rem] lg:text-3xl font-avantGardeDemi">CONCICON</p>
                </a>

              </div>
              <div className="flex items-center">
                {/* <button className="invisible md:visible bg-lightBeige text-black font-interMedium text-base py-3 px-8 rounded-full mr-12 hover:bg-darkBeige hover:text-white">Projects.</button>
                <div className="hamburger-menu flex flex-col">
                  <span className="block w-7 h-0.5 bg-black"></span>
                  <span className="block w-7 h-0.5 bg-black mt-1"></span>
                </div> */}
                {!isMobile && (
                  <div className="invisible md:visible">
                    <Magnetic>
                      <a
                        className={`bg-${bgColor} rounded-full px-8 py-3 font-denton group relative flex flex-col`}
                        href="/all-projects"
                      >
                        <span className={`group-hover:-translate-y-2 group-hover:opacity-0 transition-all duration-300 ease-out ${bgColor === "black" ? "text-white" : ""}`}>
                          Projects
                        </span>
                        <span className={`absolute translate-y-2 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-300 ease-out ${bgColor === "black" ? "text-white" : ""}`}>
                          Projects
                        </span>
                      </a>
                    </Magnetic>
                  </div>
                )}


                {!isMobile && (
                  <Magnetic>
                    <button
                      onClick={toggleMenu}
                      className="justify-self-start rounded-full px-5 font-interRegular ml-8 w-12 h-10 relative flex items-center justify-center group"
                    >
                      <motion.div
                        variants={opacity}
                        animate={!menuOpen ? "open" : "closed"}
                        className=" absolute h-fit "
                      >
                        <div className="relative flex flex-col items-center justify-center">
                          <span className="group-hover:-translate-y-2 group-hover:opacity-0 transition-all duration-500 ease-in-out absolute block w-7 h-0.5 bg-black">
                          </span>
                          <span className="group-hover:-translate-y-2 group-hover:opacity-0 transition-all duration-500 ease-in-out absolute block w-7 h-0.5 bg-black mt-3 ">
                          </span>
                          <span className="absolute translate-y-2 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-500 ease-in-out block w-7 h-0.5 bg-black">

                          </span>
                          <span className="absolute translate-y-2 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-500 ease-in-out block w-7 h-0.5 bg-black mt-3">

                          </span>
                        </div>
                      </motion.div>
                      <motion.div
                        variants={opacity}
                        animate={menuOpen ? "open" : "closed"}
                        className=" absolute h-fit opacity-0 "
                      >
                        <div className="relative flex flex-col items-center justify-center">
                          <span className="group-hover:-translate-y-2 group-hover:opacity-0 transition-all duration-500 ease-in-out absolute">
                            close
                          </span>
                          <span className="absolute translate-y-2 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-500 ease-in-out">
                            close
                          </span>
                        </div>
                      </motion.div>
                    </button>
                  </Magnetic>
                )}

                {isMobile && (
                  <button
                    onClick={toggleMenu}
                    className="justify-self-start rounded-full px-5 font-interRegular w-12 h-10 relative flex items-center justify-center group select-auto"
                  >
                    <motion.div
                      variants={opacity}
                      animate={!menuOpen ? "open" : "closed"}
                      className=" absolute h-fit "
                    >
                      <div className="relative flex flex-col items-center justify-center">
                        <span className="group-hover:-translate-y-2 group-hover:opacity-0 transition-all duration-500 ease-in-out absolute block w-7 h-0.5 bg-black">
                        </span>
                        <span className="group-hover:-translate-y-2 group-hover:opacity-0 transition-all duration-500 ease-in-out absolute block w-7 h-0.5 bg-black mt-3">
                        </span>
                        <span className="absolute translate-y-2 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-500 ease-in-out block w-7 h-0.5 bg-black">

                        </span>
                        <span className="absolute translate-y-2 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-500 ease-in-out block w-7 h-0.5 bg-black mt-3">

                        </span>
                      </div>
                    </motion.div>
                    <motion.div
                      variants={opacity}
                      animate={menuOpen ? "open" : "closed"}
                      className=" absolute h-fit opacity-0 "
                    >
                      <div className="relative flex flex-col items-center justify-center">
                        <span className="group-hover:-translate-y-2 group-hover:opacity-0 transition-all duration-500 ease-in-out absolute">
                          close
                        </span>
                        <span className="absolute translate-y-2 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-500 ease-in-out">
                          close
                        </span>
                      </div>
                    </motion.div>
                  </button>
                )}


              </div>
            </div>




          </nav>
        </div>
      </header>
      <AnimatePresence mode="wait">{menuOpen && <Menu toggleMenu={toggleMenu} />}</AnimatePresence>
    </div>
  );
}


