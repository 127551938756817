import React from 'react'

const About = () => {
    return (
        <section id='about' data-scroll-section>
            <div className='bg-black h-screen flex items-center justify-center'>
                <div className='justify-center'>
                    <p className='font-interMedium md:font-interSemibold text-beige px-5 md:px-16 lg:px-20 text-[2.15rem] leading-tight md:text-[2.5rem] md:leading-snug lg:text-[3.5rem]  lg:leading-normal'>At Concicon, we are focused on creating homes and commercials that our client can be proud of living in and working in. </p>
                </div>
            </div>
            <div className='bg-white h-screen flex items-center justify-center'>
                <p className='font-interSemibold md:font-interBold text-darkText px-5 md:px-16 lg:px-20 text-[2rem] leading-tight md:text-4xl md:leading-snug lg:text-5xl lg:leading-normal'>Our aim is to combine our professional expertise with cutting-edge technology to provide international standard of living and luxurious lifestyle within the affordable range of the Indian market. </p>
            </div>
        </section>



    )
}

export default About