import React from 'react';
import Navbar from "../components/Navbar";
import ImageComponent from '../components/projectsComponents/ImageComponent';
import TextComponent from '../components/projectsComponents/TextComponent';
import TextComponent2 from '../components/projectsComponents/TextComponent2';
import HeroProject from '../components/projectsComponents/HeroSarvodaya';
import birdview1 from '../assets/images/projects/sarvodaya/birdeye/birdeye1.jpeg';
import birdview2 from '../assets/images/projects/sarvodaya/birdeye/birdeye2.jpeg';
import birdview3 from '../assets/images/projects/sarvodaya/birdeye/birdeye3.jpeg';
import sideview1 from '../assets/images/projects/sarvodaya/sideview/sideview1.jpeg'
import sideview2 from '../assets/images/projects/sarvodaya/sideview/sideview2.jpeg';
import sideview3 from '../assets/images/projects/sarvodaya/sideview/sideview3.jpeg';
import sideview4 from '../assets/images/projects/sarvodaya/sideview/sideview4.jpeg';
import sideview5 from '../assets/images/projects/sarvodaya/sideview/sideview5.jpeg';
import sideview6 from '../assets/images/projects/sarvodaya/sideview/sideview6.jpeg';
import sideview7 from '../assets/images/projects/sarvodaya/sideview/sideview7.jpeg';
import sideview8 from '../assets/images/projects/sarvodaya/sideview/sideview8.jpeg';
import amenity1 from '../assets/images/projects/sarvodaya/amenities/childrensplayarea.jpeg';
import amenity2 from '../assets/images/projects/sarvodaya/amenities/basketball court.jpeg';
import amenity3 from '../assets/images/projects/sarvodaya/amenities/gatedenterance.jpg';
import amenity4 from '../assets/images/projects/sarvodaya/amenities/swimming.jpg';
import amenity5 from '../assets/images/projects/sarvodaya/amenities/communitycenter.jpeg';
import amenity6 from '../assets/images/projects/sarvodaya/amenities/landscape-morning.jpeg';
import amenity7 from '../assets/images/projects/sarvodaya/amenities/landscape-garden-evening.jpeg';
import amenity8 from '../assets/images/projects/sarvodaya/amenities/landscape-night.jpeg';
import amenity9 from '../assets/images/projects/sarvodaya/amenities/resident event and social activities.jpeg';
import sitePlan from '../assets/images/projects/sarvodaya/siteplan.jpeg';
import blockA11 from '../assets/images/projects/sarvodaya/a1/1.jpeg';
import blockA12 from '../assets/images/projects/sarvodaya/a1/2.jpeg';
import blockA13 from '../assets/images/projects/sarvodaya/a1/3.jpeg';
import blockA14 from '../assets/images/projects/sarvodaya/a1/4.jpeg';
import blockA1F1 from '../assets/images/projects/sarvodaya/a1/floor/F1.jpeg';
import blockA1F2 from '../assets/images/projects/sarvodaya/a1/floor/F2.jpeg';
import blockA1I1 from '../assets/images/projects/sarvodaya/a1/iso/1.jpeg';
import blockA1I2 from '../assets/images/projects/sarvodaya/a1/iso/2.jpeg';
import blockA1I3 from '../assets/images/projects/sarvodaya/a1/iso/3.jpeg';
import blockA1I4 from '../assets/images/projects/sarvodaya/a1/iso/4.jpeg';
import blockA1I5 from '../assets/images/projects/sarvodaya/a1/iso/5.jpeg';
import blockA1I6 from '../assets/images/projects/sarvodaya/a1/iso/6.jpeg';
import blockA21 from '../assets/images/projects/sarvodaya/a2/1.jpeg';
import blockA22 from '../assets/images/projects/sarvodaya/a2/2.jpeg';
import blockA23 from '../assets/images/projects/sarvodaya/a2/3.jpeg';
import blockA24 from '../assets/images/projects/sarvodaya/a2/4.jpeg';
import blockA25 from '../assets/images/projects/sarvodaya/a2/5.jpeg';
import blockA2F1 from '../assets/images/projects/sarvodaya/a2/floor/F1.jpeg';
import blockA2F2 from '../assets/images/projects/sarvodaya/a2/floor/F2.jpeg';
import blockA2I1 from '../assets/images/projects/sarvodaya/a2/iso/1.jpeg';
import blockA2I2 from '../assets/images/projects/sarvodaya/a2/iso/2.jpeg';
import blockA2I3 from '../assets/images/projects/sarvodaya/a2/iso/3.jpeg';
import blockA2I4 from '../assets/images/projects/sarvodaya/a2/iso/4.jpeg';
import blockA2I5 from '../assets/images/projects/sarvodaya/a2/iso/5.jpeg';
import blockA2I6 from '../assets/images/projects/sarvodaya/a2/iso/6.jpeg';
import blockA2I7 from '../assets/images/projects/sarvodaya/a2/iso/7.jpeg';
import blockB1 from '../assets/images/projects/sarvodaya/b/1.jpeg';
import blockB2 from '../assets/images/projects/sarvodaya/b/2.jpeg';
import blockB3 from '../assets/images/projects/sarvodaya/b/3.jpeg';
import blockB4 from '../assets/images/projects/sarvodaya/b/4.jpeg';
import blockBF1 from '../assets/images/projects/sarvodaya/b/floor/F1.jpeg';
import blockBF2 from '../assets/images/projects/sarvodaya/b/floor/F2.jpeg';
import blockBF3 from '../assets/images/projects/sarvodaya/b/floor/F3.jpeg';
import blockBI1 from '../assets/images/projects/sarvodaya/b/iso/1.jpeg';
import blockC1 from '../assets/images/projects/sarvodaya/c/1.jpeg';
import blockC2 from '../assets/images/projects/sarvodaya/c/2.jpeg';
import blockC3 from '../assets/images/projects/sarvodaya/c/3.jpeg';
import blockC4 from '../assets/images/projects/sarvodaya/c/4.jpeg';
import blockCF1 from '../assets/images/projects/sarvodaya/c/floor/F1.jpeg';
import blockCF2 from '../assets/images/projects/sarvodaya/c/floor/F2.jpeg';
import blockCF3 from '../assets/images/projects/sarvodaya/c/floor/F3.jpeg';
import blockCI1 from '../assets/images/projects/sarvodaya/c/iso/1.jpeg';
import blockCI2 from '../assets/images/projects/sarvodaya/c/iso/2.jpeg';
import blockCI3 from '../assets/images/projects/sarvodaya/c/iso/3.jpeg';
import blockCI4 from '../assets/images/projects/sarvodaya/c/iso/4.jpeg';
import blockCI5 from '../assets/images/projects/sarvodaya/c/iso/5.jpeg';
import blockCI6 from '../assets/images/projects/sarvodaya/c/iso/6.jpeg';
import blockD1 from '../assets/images/projects/sarvodaya/d/1.jpeg';
import blockD2 from '../assets/images/projects/sarvodaya/d/2.jpeg';
import blockD3 from '../assets/images/projects/sarvodaya/d/3.jpeg';
import blockD4 from '../assets/images/projects/sarvodaya/d/4.jpeg';
import blockD5 from '../assets/images/projects/sarvodaya/d/5.jpeg';
import blockDF1 from '../assets/images/projects/sarvodaya/d/floor/F1.jpeg';
import blockDF2 from '../assets/images/projects/sarvodaya/d/floor/F1.jpeg';
import blockDI1 from '../assets/images/projects/sarvodaya/d/iso/1.jpeg';
import blockDI2 from '../assets/images/projects/sarvodaya/d/iso/2.jpeg';
import blockDI3 from '../assets/images/projects/sarvodaya/d/iso/3.jpeg';
import blockDI4 from '../assets/images/projects/sarvodaya/d/iso/4.jpeg';
import blockDI5 from '../assets/images/projects/sarvodaya/d/iso/5.jpeg';
import blockDI6 from '../assets/images/projects/sarvodaya/d/iso/6.jpeg';
import ews1 from '../assets/images/projects/sarvodaya/ews/1.jpeg';
import ews2 from '../assets/images/projects/sarvodaya/ews/2.jpeg';
import ews3 from '../assets/images/projects/sarvodaya/ews/3.jpeg';
import ews4 from '../assets/images/projects/sarvodaya/ews/4.jpeg';
import ewsF1 from '../assets/images/projects/sarvodaya/ews/floor/F1.jpeg';
import front1 from '../assets/images/projects/sarvodaya/front/1.jpeg';
import front2 from '../assets/images/projects/sarvodaya/front/2.jpeg';
import front3 from '../assets/images/projects/sarvodaya/front/3.jpeg';
import front4 from '../assets/images/projects/sarvodaya/front/4.jpeg';
import common1 from '../assets/images/projects/sarvodaya/common/1.jpeg';
import common2 from '../assets/images/projects/sarvodaya/common/2.jpeg';
import common3 from '../assets/images/projects/sarvodaya/common/3.jpeg';
import common4 from '../assets/images/projects/sarvodaya/common/4.jpeg';

import Footer from '../components/Footer';




import { Navigation, Pagination, Scrollbar, A11y, EffectCreative } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from 'react-responsive';
import CustomCursor from '../components/customCursor';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-creative';


const Sarvodaya = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    return (
        <div className='bg-[#E6E9F4]'>
            {!isMobile && (
                <CustomCursor />
            )}
            <Navbar bgColor="black" headerColor="[#E6E9F4]" />
            <HeroProject />

            {/* <ImageComponent name='Bird Eye View' src={birdview} /> */}
            {/* "BIRD EYE VIEW" */}
            <div className='birdeye'>
                <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                    <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Bird Eye View</p>
                </div>
                <div className=''>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Scrollbar, A11y, EffectCreative, Pagination]}
                        pagination={{ clickable: true }}
                        spaceBetween={25}
                        slidesPerView={1}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        effect='creative'
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-121%', 0, -500],
                            },
                            next: {
                                shadow: true,
                                translate: ['121%', 0, -500],
                            },
                        }}
                    >
                        <SwiperSlide><img src={birdview1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={birdview2} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={birdview3} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>

                    </Swiper>
                </div>
            </div>

            <TextComponent heading="The Shine of Your Lifestyle" desc="Sarvodaya City is one of the most awaited and prestigious projects by Concicon Constructions which is situated at one of the best location of the Patna city. It is one of those strategically planned apartments which promise ceaseless comfort and convenience to people." />

            {/* <ImageComponent name='Side View' src={sideview} margin='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'/> */}
            {/* "SIDE VIEW" */}
            <div className='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'>
                <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                    <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Side View</p>
                </div>
                <div className=''>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative]}
                        pagination={{ clickable: true }}
                        spaceBetween={25}
                        slidesPerView={1}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        effect='creative'
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-121%', 0, -500],
                            },
                            next: {
                                shadow: true,
                                translate: ['121%', 0, -500],
                            },
                        }}
                    >
                        <SwiperSlide><img src={sideview1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={sideview2} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={sideview3} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={sideview4} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={sideview5} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={sideview6} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={sideview7} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={sideview8} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                    </Swiper>
                </div>
            </div>

            {/* "FRONT VIEW"*/}
            <div className='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'>
                <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                    <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Front View</p>
                </div>
                <div className=''>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative]}
                        pagination={{ clickable: true }}
                        spaceBetween={25}
                        slidesPerView={1}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        effect='creative'
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-121%', 0, -500],
                            },
                            next: {
                                shadow: true,
                                translate: ['121%', 0, -500],
                            },
                        }}
                    >
                        <SwiperSlide><img src={front1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={front2} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={front3} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={front4} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                    </Swiper>
                </div>
            </div>

            {/* <ImageComponent name='Amenities' margin='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36' src={landscapeGardening} /> */}
            {/* "AMENITIES" */}
            <div className='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'>
                <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                    <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Amenities</p>
                </div>
                <div className=''>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative]}
                        pagination={{ clickable: true }}
                        spaceBetween={25}
                        slidesPerView={1}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        effect='creative'
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-121%', 0, -500],
                            },
                            next: {
                                shadow: true,
                                translate: ['121%', 0, -500],
                            },
                        }}
                    >
                        <SwiperSlide><img src={amenity1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /><TextComponent2 heading='Childrens Play Area' desc='A dedicated area for the childrens to play. Your Children would love hanging out in this place.' /></SwiperSlide>
                        <SwiperSlide><img src={amenity2} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /><TextComponent2 heading='Basketball Court' desc='A dedicated area for the childrens to play. Your Children would love hanging out in this place.' /></SwiperSlide>
                        <SwiperSlide><img src={amenity3} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /><TextComponent2 heading='Gated Enterance' desc='A dedicated area for the childrens to play. Your Children would love hanging out in this place.' /></SwiperSlide>
                        <SwiperSlide><img src={amenity4} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /><TextComponent2 heading='Swimming Pool' desc='A dedicated area for the childrens to play. Your Children would love hanging out in this place.' /></SwiperSlide>
                        <SwiperSlide><img src={amenity5} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /><TextComponent2 heading='Community Center' desc='A dedicated area for the childrens to play. Your Children would love hanging out in this place.' /></SwiperSlide>
                        <SwiperSlide><img src={amenity6} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /><TextComponent2 heading='Landscape Garden' desc='A dedicated area for the childrens to play. Your Children would love hanging out in this place.' /></SwiperSlide>
                        <SwiperSlide><img src={amenity7} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /><TextComponent2 heading='Landscape Garden' desc='A dedicated area for the childrens to play. Your Children would love hanging out in this place.' /></SwiperSlide>
                        <SwiperSlide><img src={amenity8} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /><TextComponent2 heading='Landscape Garden' desc='A dedicated area for the childrens to play. Your Children would love hanging out in this place.' /></SwiperSlide>
                        <SwiperSlide><img src={amenity9} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /><TextComponent2 heading='Social Activities Area' desc='A dedicated area for the childrens to play. Your Children would love hanging out in this place.' /></SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <ImageComponent name='Site Plan' margin='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36' src={sitePlan} />

            {/* "Common Area" */}
            <div className='common'>
                <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                    <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Common Area</p>
                </div>
                <div className=''>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Scrollbar, A11y, EffectCreative, Pagination]}
                        pagination={{ clickable: true }}
                        spaceBetween={25}
                        slidesPerView={1}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        effect='creative'
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-121%', 0, -500],
                            },
                            next: {
                                shadow: true,
                                translate: ['121%', 0, -500],
                            },
                        }}
                    >
                        <SwiperSlide><img src={common1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={common2} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={common3} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={common4} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                    </Swiper>
                </div>
            </div>

            {/* "Block A1" */}
            <div className='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'>
                <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                    <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Block A1</p>
                </div>
                <div className=''>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative]}
                        pagination={{ clickable: true }}
                        spaceBetween={25}
                        slidesPerView={1}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        effect='creative'
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-121%', 0, -500],
                            },
                            next: {
                                shadow: true,
                                translate: ['121%', 0, -500],
                            },
                        }}
                    >
                        <SwiperSlide><img src={blockA11} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA12} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA13} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA14} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA1F1} alt='' className='w-screen h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA1F2} alt='' className='w-screen h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA1I1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA1I2} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA1I3} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA1I4} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA1I5} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA1I6} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                    </Swiper>
                </div>
            </div>
            {/* <ImageComponent name='Block A1' margin='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36' src={blockA1} /> */}

            {/* "Block A2" */}
            {/* <ImageComponent name='Block A2' margin='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36' src={blockA2} /> */}
            <div className='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'>
                <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                    <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Block A2</p>
                </div>
                <div className=''>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative]}
                        pagination={{ clickable: true }}
                        spaceBetween={25}
                        slidesPerView={1}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        effect='creative'
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-121%', 0, -500],
                            },
                            next: {
                                shadow: true,
                                translate: ['121%', 0, -500],
                            },
                        }}
                    >
                        <SwiperSlide><img src={blockA21} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA22} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA23} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA24} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA25} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA2F1} alt='' className='w-screen h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA2F2} alt='' className='w-screen h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA2I1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA2I2} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA2I3} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA2I4} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA2I5} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA2I6} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockA2I7} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                    </Swiper>
                </div>
            </div>

            {/* "Block B" */}
            {/* <ImageComponent name='Block B' margin='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36' src={blockB} /> */}
            <div className='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'>
                <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                    <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Block B</p>
                </div>
                <div className=''>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative]}
                        pagination={{ clickable: true }}
                        spaceBetween={25}
                        slidesPerView={1}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        effect='creative'
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-121%', 0, -500],
                            },
                            next: {
                                shadow: true,
                                translate: ['121%', 0, -500],
                            },
                        }}
                    >
                        <SwiperSlide><img src={blockB1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockB2} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockB3} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockB4} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockBF1} alt='' className='w-screen h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockBF2} alt='' className='w-screen h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockBF3} alt='' className='w-screen h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockBI1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>

                    </Swiper>
                </div>
            </div>

            {/* "Block C" */}
            {/* <ImageComponent name='Block C' margin='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36' src={blockC} /> */}
            <div className='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'>
                <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                    <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Block C</p>
                </div>
                <div className=''>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative]}
                        pagination={{ clickable: true }}
                        spaceBetween={25}
                        slidesPerView={1}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        effect='creative'
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-121%', 0, -500],
                            },
                            next: {
                                shadow: true,
                                translate: ['121%', 0, -500],
                            },
                        }}
                    >
                        <SwiperSlide><img src={blockC1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockC2} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockC3} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockC4} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockCF1} alt='' className='w-screen h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockCF2} alt='' className='w-screen h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockCF3} alt='' className='w-screen h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockCI1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockCI2} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockCI3} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockCI4} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockCI5} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockCI6} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>

                    </Swiper>
                </div>
            </div>

            {/* "Block D" */}
            {/* <ImageComponent name='Block D' margin='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36' src={blockD} /> */}
            <div className='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'>
                <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                    <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Block D</p>
                </div>
                <div className=''>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative]}
                        pagination={{ clickable: true }}
                        spaceBetween={25}
                        slidesPerView={1}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        effect='creative'
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-121%', 0, -500],
                            },
                            next: {
                                shadow: true,
                                translate: ['121%', 0, -500],
                            },
                        }}
                    >
                        <SwiperSlide><img src={blockD1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockD2} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockD3} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockD4} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockD5} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockDF1} alt='' className='w-screen h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockDF2} alt='' className='w-screen h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockDI1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockDI2} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockDI3} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockDI4} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockDI5} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={blockDI6} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                    </Swiper>
                </div>
            </div>

            {/* "Block EWS" */}
            {/* <ImageComponent name='Block EWS' margin='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36' src={blockEWS} /> */}
            <div className='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'>
                <div className='flex flex-row-reverse mt-10 sm:mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
                    <p className='font-interMedium text-[0.8rem] sm:text-[0.85rem] md:text-[0.9rem] lg:text-[1rem] xl:text-[1.2rem] mr-[3vw]'>Block EWS</p>
                </div>
                <div className=''>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectCreative]}
                        pagination={{ clickable: true }}
                        spaceBetween={25}
                        slidesPerView={1}
                        navigation
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        effect='creative'
                        creativeEffect={{
                            prev: {
                                shadow: true,
                                translate: ['-121%', 0, -500],
                            },
                            next: {
                                shadow: true,
                                translate: ['121%', 0, -500],
                            },
                        }}
                    >
                        <SwiperSlide><img src={ews1} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={ews2} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={ews3} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={ews4} alt='' className='w-screen object-cover h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>
                        <SwiperSlide><img src={ewsF1} alt='' className='w-screen h-[35vh] sm:h-[50vh] lg:h-[100vh]' /></SwiperSlide>

                    </Swiper>
                </div>
            </div>

            <TextComponent heading='City within a City' margin='pb-10' desc='A township can be defined as a City within a City. The idea behind township living is to serve the ultimate Live-Work-Play concept to its residents by making everyday facilities easily available. Township living offers all the advantages of city living, and everything is accessible on foot.' />
            {/* <ImageComponent name='Community Center' margin='mx-6 sm:mx-16 lg:mx-28 2xl:mx-36 pb-10 md:pb-20' src= {communityCenter}/> */}
            
        </div>

    )
}

export default Sarvodaya