import React from 'react'
import { useMediaQuery } from 'react-responsive'

const HeroVeena = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    return (
        <div>
            <div className='pt-44 sm:pt-36 md:pt-40 xl:pt-44 2xl:pt-40 flex justify-center align-bottom mx-6 sm:mx-16 lg:mx-28 2xl:mx-36'>
                <div className=' relative'>
                    <div className='flex flex-col sm:flex-row justify-between'>
                        <div className='sm:mr-3 md:mr-5 lg:mr-10 xl:mr-40 2xl:mr-20 z-10 flex flex-col items-center sm:flex-none sm:items-start'>
                            <p className='font-timesNewRomanItalic text-[2.3rem] sm:text-[2.3rem] md:text-[3rem] lg:text-[3.8rem] xl:text-[4.2rem] 2xl:text-[4.6rem] '>PROJECTS/</p>
                            <p className='text-[2.3rem] sm:text-[2.3rem] md:text-[2.7rem] lg:text-[3.8rem] xl:text-[4.2rem] 2xl:text-[4.6rem] font-interMedium tracking-[-0.15rem] sm:tracking-[-0.2rem] lg:tracking-[-0.4rem] -mt-3 sm:-mt-6 sel'>VEENA VIHAR</p>
                        </div>
                        <div className='mt-6 sm:mt-24 md:mt-32 lg:mt-40 xl:mt-52 z-10 flex flex-col items-center sm:flex-none sm:items-start'>
                            <p className='text-[2.3rem] sm:text-[2.3rem] md:text-[2.7rem] lg:text-[3.8rem] xl:text-[4.2rem] 2xl:text-[4.6rem] font-interMedium tracking-[-0.15rem] sm:tracking-[-0.2rem] lg:tracking-[-0.4rem] '>RESIDENTIAL</p>
                            <p className='font-timesNewRomanItalic text-[2.3rem] sm:text-[2.3rem] md:text-[3rem] lg:text-[3.8rem] xl:text-[4.2rem] 2xl:text-[4.6rem] -mt-3 sm:-mt-6'>BUILDING</p>
                        </div>
                    </div>

                    <div className='absolute top-[85%] sm:top-[80%] xl:top-[75%] left-[-25%] sm:left-[15%] xl:left-[23%] rotate-18'>
                        <p className='text-[#D9D6D6] font-notoSansBold font-bold text-[22rem] sm:text-[23rem] md:text-[28rem] lg:text-[34rem] xl:text-[40rem] 2xl:text-[45rem] leading-10 opacity-50 drop-shadow-bgText uppercase'>V</p>
                    </div>

                </div>
            </div>
            <div className='flex flex-col sm:flex-row mt-28 sm:mt-20 justify-between font-avantGardeBook mx-6 sm:mx-16 lg:mx-28 2xl:mx-40'>
                <div className='sm:w-2/4'>
                    <p className='uppercase text-[0.85rem] lg:text-[1rem] mb-3 xl:mb-5'>Project Overview</p>
                    <p className='font-interRegular text-[0.9rem] sm:text-[1rem] md:text-[1.15rem] lg:text-[1.25rem] xl:text-[1.55rem] 2xl:text-[1.8rem] leading-[125%] '>Veena Vihar is a testament to our commitment as a construction firm to create structures that are not just buildings, but a holistic living experience.</p>
                </div>
                <div className='flex justify-between mt-8 sm:mt-0 md:w-2/5'>
                    <div className='mr-10 lg:mr-16 xl:mr-20'>
                        <p className='uppercase text-[0.85rem] lg:text-[1rem] mb-3 xl:mb-5'>PROJECT DETAILS</p>
                        <div className='text-[0.95rem] sm:text-[0.8rem] md:text-[1rem] xl:text-[1.2rem] 2xl:text-[1.35rem] font-interRegular'>
                            <p>Sold Out!</p>
                            <p>Rs.5500/sq.ft.</p>
                            <p>126 apartments</p>
                            <p>65864 sqft. area</p>
                            <p>2,3,4 BHK Flats</p>
                        </div>
                    </div>
                    <div className='w-1/2 md:w-2/4'>
                        <p className='uppercase text-[0.85rem] lg:text-[1rem] mb-3 xl:mb-5'>LOCATION</p>
                        <p className='text-[0.95rem] sm:text-[0.8rem] md:text-[1rem] xl:text-[1.2rem] 2xl:text-[1.35rem] mb-4 lg:mb-6 font-interRegular'>Khagaul Road, Patna</p>
                        <button className="flex justify-center items-center font-interMedium bg-black text-white text-[0.75rem] md:text-xs xl:text-sm py-[0.65rem] md:py-[0.75rem] lg:py-[0.8rem] px-6 lg:px-7 rounded-full whitespace-nowrap">
                            Raise a Quote
                            {!isMobile && (
                                <div className=' ml-[0.3rem] lg:ml-[0.4rem]'>
                                    <svg width="10" height="10" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.05 15.625C14.0875 13.675 15.5625 11.6625 15.5625 8.3C15.3625 8.3625 15.1875 8.3625 15.0125 8.3625C13.425 8.3625 11.8875 7.2875 11.8875 5.35C11.8875 3.3375 13.175 2.0875 15.0125 2.0875C17.3875 2.0875 18.75 3.9875 18.75 7.4C18.75 12.15 16.5625 15.5625 12.475 17.925L11.05 15.625ZM2.3 15.625C5.3375 13.675 6.8125 11.6625 6.8125 8.3C6.6125 8.3625 6.4375 8.3625 6.2625 8.3625C4.675 8.3625 3.1375 7.2875 3.1375 5.35C3.1375 3.3375 4.425 2.0875 6.2625 2.0875C8.625 2.0875 9.9875 3.9875 9.9875 7.4C9.9875 12.15 7.8 15.5625 3.7125 17.925L2.2875 15.625L2.3 15.625Z" fill="white" />
                                    </svg>
                                </div>
                            )}
                            {isMobile && (
                                <div className=' ml-[0.2rem] lg:ml-[0.5rem]'>
                                    <svg width="9" height="9" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.05 15.625C14.0875 13.675 15.5625 11.6625 15.5625 8.3C15.3625 8.3625 15.1875 8.3625 15.0125 8.3625C13.425 8.3625 11.8875 7.2875 11.8875 5.35C11.8875 3.3375 13.175 2.0875 15.0125 2.0875C17.3875 2.0875 18.75 3.9875 18.75 7.4C18.75 12.15 16.5625 15.5625 12.475 17.925L11.05 15.625ZM2.3 15.625C5.3375 13.675 6.8125 11.6625 6.8125 8.3C6.6125 8.3625 6.4375 8.3625 6.2625 8.3625C4.675 8.3625 3.1375 7.2875 3.1375 5.35C3.1375 3.3375 4.425 2.0875 6.2625 2.0875C8.625 2.0875 9.9875 3.9875 9.9875 7.4C9.9875 12.15 7.8 15.5625 3.7125 17.925L2.2875 15.625L2.3 15.625Z" fill="white" />
                                    </svg>
                                </div>
                            )}
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default HeroVeena