import React from 'react'
import ClientImg from "../assets/images/ClientImg.png";


function Testimonial() {
  return (
    <section className='h-screen flex justify-center items-center' data-scroll-section>
      <div className='bg-white  flex flex-col md:flex-row items-center justify-center w-11/12 lg:w-10/12 md:mt-12'>
        <div className='w-10/12 sm:w-4/6 md:w-1/2 flex flex-col'>
          <div className='flex flex-col mb-9 md:mb-10 lg:mb-12 '>
            <div className='mb-3 md:mb-5 text-center md:text-left'>
              <p className='italic text-[1.6rem] md:text-[1.7rem] lg:text-[2.2rem] xl:text-[2.5rem] 2xl:text-[2.625rem] leading-10'>Building <span className='font-timesNewRomanItalic'>Trust,</span></p>
              <p className='italic text-[1.59rem] md:text-[1.7rem] lg:text-[2.2rem] xl:text-[2.5rem] 2xl:text-[2.625rem]'>One Testimonial at a <span className=' font-timesNewRomanItalic'>Time.</span></p>
            </div>

            <p className='text-center md:text-left self-center md:self-auto font-avantGardeBook text-[1rem] md:text-[1.25rem] lg:text-[1.3rem] 2xl:text-[1.5rem] leading-[1.4rem] sm:leading-[1.5rem] md:leading-[1.9rem] w-full md:w-4/5'>Hear directly from our satisfied clients who have
              experienced the quality and reliability of our
              construction services.</p>
          </div>

          <button className="self-center md:self-start button-bg-color text-black text-sm md:text-base lg:text-lg py-3 xl:py-4 px-7 md:px-8 lg:px-9 xl:px-10 rounded-full bottom-20 font-interMedium">
            Raise a Quote "
          </button>
        </div>

        <div className='w-10/12 sm:w-3/5 md:w-1/2 mt-16 md:mt-0'>
          <p className='font-avantGardeDemi text-[1.3rem] md:text-[1.5rem] lg:text-[2.1rem] xl:text-[2.3rem] leading-snug xl:leading-[3rem] tracking-[0.013rem]'>“The team at Concicon exceeded
            our expectations at every turn.
            They completed our office
            building on time and within
            budget, without compromising on
            quality. We’re extremely satisfied
            with their work.”</p>

          <div className='flex align-center pt-5 sm:pt-8 md:pt-12'>
            <div>
              <img src={ClientImg} alt="ClientImg" className="h-12 sm:h-12 md:h-14 lg:h-16 xl:h-18 " />
            </div>
            <div className='flex flex-col justify-center pl-5'>
              <h4 className='text-sm sm:text-base md:text-lg font-avantGardeBook'>Client</h4>
              <h4 className='text-sm sm:text-base md:text-lg font-interRegular'>Abhijeet Anand</h4>
            </div>
          </div>

        </div>
      </div>

    </section>
  )
}

export default Testimonial