import React from 'react'

const TextComponent = (props) => {
    return (
        <div className={`${props.margin}`}>
            <div className='md:mx-10'>
                <div className='flex flex-col md:flex-row justify-between pt-6 md:pt-12 pb-16'>
                    <p className=' md:w-2/5 text-[1.05rem] sm:text-[1.2rem] md:text-[1.3rem] lg:text-[1.4rem] xl:text-[1.6rem] 2xl:text-[1.8rem] font-interMedium leading-[140%] md:leading-[150%]'>{props.heading}</p>
                    <p className='mt-3 md:mt-0 font-avantGardeBook text-[0.95rem] sm:text-[1rem] md:text-[1.1rem] lg:text-[1.3rem] xl:text-[1.4rem] 2xl:text-[1.4rem] md:w-2/4 leading-[140%]'>{props.desc}</p>
                </div>
            </div>
        </div>
        
    )
}

export default TextComponent