import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

import Navbar from '../../components/Navbar'
import background from '../../assets/images/background-allprojects.jpg'
import sarvodaya from '../../assets/images/sarvodaya-birdeye.jpeg'
import VeenaVihar from '../../assets/images/projects/veenavihar/birdeye1.jpeg'
import tranquil from '../../assets/images/tranquil.jpeg'
import shoppersMall from '../../assets/images/shoppers.jpeg'

import './style.scss'
import CustomCursor from '../../components/customCursor'

import './style.scss'

import { db } from '../../utils/firebase'
import { collection, getDocs } from 'firebase/firestore'

let completedProjects = {}
let ongoingProjects = {}
let upcomingProjects = {}

const AllProjects = () => {
  const [activeTab, setActiveTab] = useState('completed')
  //   const [projects, setProjects] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const fetchProjects = async () => {
    const querySnapshot = await getDocs(collection(db, 'projects'))
    const projectsList = querySnapshot.docs.map(doc => doc.data())
    // await setProjects(projectsList)
    // console.log(projectsList[0]);
    completedProjects = projectsList[0]
    ongoingProjects = projectsList[1]
    upcomingProjects = projectsList[2]
    console.log(completedProjects)
    console.log(Object.keys(completedProjects))
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await fetchProjects()
      setIsLoading(false)
    }
    fetchData()
  }, [])

  return (
    <div className='all-projects-main'>
      {!isMobile && <CustomCursor />}
      <Navbar bgColor='black' />
      {!isLoading && (
        <div className='px-5 sm:px-8 md:px-10 pt-24 md:pt-32'>
          <div className=''>
            <p className=' font-interMedium text-[1.8rem] md:text-[2.2rem] lg:text-[2.5rem] xl:text-[3rem] pb-4'>
              Our Projects
            </p>
            <p className='font-interRegular lg:w-10/12 xl:w-9/12 text-[0.9rem] md:text-[1rem] lg:text-[1.15rem] leading-relaxed md:leading-normal'>
              At Concicon, we don't just build structures, we craft living
              spaces that inspire and empower. From modern, amenity-rich
              apartments to timeless, architecturally striking buildings, our
              portfolio showcases a commitment to quality, innovation, and
              exceeding expectations. Whether you're seeking the perfect place
              to call home or a breathtaking space for your business to thrive,
              explore our diverse range of projects and discover the potential
              for your future within our walls.
            </p>
          </div>

          <div>
            <div className='flex gap-4 lg:gap-6 pt-12 md:pt-16 font-interRegular text-[0.9375rem] md:text-[1rem] lg:text-[1.1rem]'>
              <button
                onClick={() => setActiveTab('completed')}
                className={
                  activeTab === 'completed'
                    ? 'underline underline-offset-4'
                    : ''
                }
              >
                Completed
              </button>
              <button
                onClick={() => setActiveTab('ongoing')}
                className={
                  activeTab === 'ongoing' ? 'underline underline-offset-4' : ''
                }
              >
                Ongoing
              </button>
              <button
                onClick={() => setActiveTab('upcoming')}
                className={
                  activeTab === 'upcoming' ? 'underline underline-offset-4' : ''
                }
              >
                Upcoming
              </button>
            </div>
            {/* {!isLoading && <p>{JSON.stringify(projects)}</p>} */}
            {/* <p>{JSON.stringify(completedProjects)}</p> */}

            {activeTab === 'completed' && (
              <div>
                {/* Content for Completed */}
                <div className='grid grid-flow-row sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-10 pb-10 font-interRegular text-[1.1rem] sm:text-[1.2rem] md:text-[1.2rem] lg:text-[1.3rem] xl:text-[1.35rem] 2xl:text-[1.5rem]'>
                  {Object.keys(completedProjects).map(key => {
                    const project = completedProjects[key]
                    return (
                      <a
                        href={`/projects/${project.url}`}
                        key={key}
                      >
                        <div className='flex flex-col gap-3 justify-center'>
                          <div className='flex justify-center h-[16rem] sm:h-[14rem] md:h-[16rem] xl:h-[19rem] 2xl:h-[20.8rem] overflow-clip rounded-xl'>
                            <img
                              src={project.thumbnail}
                              alt={project.name}
                              className='h-full object-cover'
                            />
                          </div>
                          <div className='flex justify-between'>
                            <div>
                              <p>{project.name}</p>
                              <p className='text-[0.8rem] lg:text-[0.9rem]'>
                                {project.type}
                              </p>
                            </div>
                            <svg
                              width='59'
                              height='59'
                              viewBox='0 0 40 59'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M38.9527 35.0768L38.9121 19.4556L23.2909 19.415C23.1282 19.393 22.9626 19.4062 22.8054 19.4537C22.6481 19.5012 22.503 19.582 22.3797 19.6905C22.2564 19.799 22.1579 19.9327 22.0908 20.0826C22.0236 20.2325 21.9895 20.3951 21.9907 20.5593C21.9919 20.7235 22.0283 20.8856 22.0976 21.0345C22.1668 21.1834 22.2672 21.3157 22.3921 21.4225C22.5169 21.5292 22.6632 21.6079 22.8211 21.6531C22.9789 21.6984 23.1447 21.7092 23.3071 21.6849L35.0129 21.7335L19.5052 37.2412C19.2902 37.4562 19.1694 37.7478 19.1694 38.0519C19.1694 38.3559 19.2902 38.6475 19.5052 38.8625C19.7202 39.0775 20.0118 39.1983 20.3158 39.1983C20.6199 39.1983 20.9115 39.0775 21.1265 38.8625L36.6342 23.3548L36.6828 35.0606C36.6839 35.3648 36.8058 35.6561 37.0217 35.8705C37.2375 36.0848 37.5297 36.2047 37.834 36.2036C38.1382 36.2025 38.4295 36.0806 38.6439 35.8648C38.8582 35.6489 38.978 35.3567 38.977 35.0525L38.9527 35.0768Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                        </div>
                      </a>
                    )
                  })}
                </div>
              </div>
            )}

            {activeTab === 'ongoing' && (
              <div>
                {/* Content for Ongoing */}
                <div className='grid grid-flow-row sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-10 pb-10 font-interRegular text-[1.1rem] sm:text-[1.2rem] md:text-[1.2rem] lg:text-[1.3rem] xl:text-[1.35rem] 2xl:text-[1.5rem]'>
                  {Object.keys(ongoingProjects).map(key => {
                    const project = ongoingProjects[key]
                    return (
                      <a
                        href={`/projects/${project.url}`}
                        key={key}
                      >
                        <div className='flex flex-col gap-3 justify-center'>
                          <div className='flex justify-center h-[16rem] sm:h-[14rem] md:h-[16rem] xl:h-[19rem] 2xl:h-[20.8rem] overflow-clip rounded-xl'>
                            <img
                              src={project.thumbnail}
                              alt={project.name}
                              className='h-full object-cover'
                            />
                          </div>
                          <div className='flex justify-between'>
                            <div>
                              <p>{project.name}</p>
                              <p className='text-[0.8rem] lg:text-[0.9rem]'>
                                {project.type}
                              </p>
                            </div>
                            <svg
                              width='59'
                              height='59'
                              viewBox='0 0 40 59'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M38.9527 35.0768L38.9121 19.4556L23.2909 19.415C23.1282 19.393 22.9626 19.4062 22.8054 19.4537C22.6481 19.5012 22.503 19.582 22.3797 19.6905C22.2564 19.799 22.1579 19.9327 22.0908 20.0826C22.0236 20.2325 21.9895 20.3951 21.9907 20.5593C21.9919 20.7235 22.0283 20.8856 22.0976 21.0345C22.1668 21.1834 22.2672 21.3157 22.3921 21.4225C22.5169 21.5292 22.6632 21.6079 22.8211 21.6531C22.9789 21.6984 23.1447 21.7092 23.3071 21.6849L35.0129 21.7335L19.5052 37.2412C19.2902 37.4562 19.1694 37.7478 19.1694 38.0519C19.1694 38.3559 19.2902 38.6475 19.5052 38.8625C19.7202 39.0775 20.0118 39.1983 20.3158 39.1983C20.6199 39.1983 20.9115 39.0775 21.1265 38.8625L36.6342 23.3548L36.6828 35.0606C36.6839 35.3648 36.8058 35.6561 37.0217 35.8705C37.2375 36.0848 37.5297 36.2047 37.834 36.2036C38.1382 36.2025 38.4295 36.0806 38.6439 35.8648C38.8582 35.6489 38.978 35.3567 38.977 35.0525L38.9527 35.0768Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                        </div>
                      </a>
                    )
                  })}
                </div>
              </div>
            )}

            {activeTab === 'upcoming' && (
              <div>
                {/* Content for Upcoming */}
                <div className='grid grid-flow-row sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-10 pb-10 font-interRegular text-[1.1rem] sm:text-[1.2rem] md:text-[1.2rem] lg:text-[1.3rem] xl:text-[1.35rem] 2xl:text-[1.5rem]'>
                  {Object.keys(upcomingProjects).map(key => {
                    const project = upcomingProjects[key]
                    return (
                      <a
                        href={`/projects/${project.url}`}
                        key={key}
                      >
                        <div className='flex flex-col gap-3 justify-center'>
                          <div className='flex justify-center h-[16rem] sm:h-[14rem] md:h-[16rem] xl:h-[19rem] 2xl:h-[20.8rem] overflow-clip rounded-xl'>
                            <img
                              src={project.thumbnail}
                              alt={project.name}
                              className='h-full object-cover'
                            />
                          </div>
                          <div className='flex justify-between'>
                            <div>
                              <p>{project.name}</p>
                              <p className='text-[0.8rem] lg:text-[0.9rem]'>
                                {project.type}
                              </p>
                            </div>
                            <svg
                              width='59'
                              height='59'
                              viewBox='0 0 40 59'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M38.9527 35.0768L38.9121 19.4556L23.2909 19.415C23.1282 19.393 22.9626 19.4062 22.8054 19.4537C22.6481 19.5012 22.503 19.582 22.3797 19.6905C22.2564 19.799 22.1579 19.9327 22.0908 20.0826C22.0236 20.2325 21.9895 20.3951 21.9907 20.5593C21.9919 20.7235 22.0283 20.8856 22.0976 21.0345C22.1668 21.1834 22.2672 21.3157 22.3921 21.4225C22.5169 21.5292 22.6632 21.6079 22.8211 21.6531C22.9789 21.6984 23.1447 21.7092 23.3071 21.6849L35.0129 21.7335L19.5052 37.2412C19.2902 37.4562 19.1694 37.7478 19.1694 38.0519C19.1694 38.3559 19.2902 38.6475 19.5052 38.8625C19.7202 39.0775 20.0118 39.1983 20.3158 39.1983C20.6199 39.1983 20.9115 39.0775 21.1265 38.8625L36.6342 23.3548L36.6828 35.0606C36.6839 35.3648 36.8058 35.6561 37.0217 35.8705C37.2375 36.0848 37.5297 36.2047 37.834 36.2036C38.1382 36.2025 38.4295 36.0806 38.6439 35.8648C38.8582 35.6489 38.978 35.3567 38.977 35.0525L38.9527 35.0768Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                        </div>
                      </a>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default AllProjects
