import React, { useRef } from 'react'
import HeroImg from "../assets/images/hero-full.png";
import { useMediaQuery } from 'react-responsive'
import Magnetic from "./Magnetic";
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import SplitType from 'split-type';
import ScrollTrigger from 'gsap/ScrollTrigger';


gsap.registerPlugin(ScrollTrigger)


const Hero = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })


  const heroTextRef = useRef();

  let tl = gsap.timeline({
    defaults: {
      duration: 1,
      ease: 'power3',
    }
  });

  useGSAP(() => {
    const text = new SplitType('#target', { types: 'words' })
    const text1 = new SplitType(text.words, { types: 'words' })

    gsap.from(text1.words, {
      yPercent: 100,
      duration: 1.3,
      stagger: 0.015,
      ease: 'power3'
    })
  }, { scope: heroTextRef })

  useGSAP(() => {
    tl.to(".heroButton", {
      scale: 1,
      delay: 0.5,
      duration: 0.5,
    })
      .to(".text-wrapper", {
        width: "auto",
      }, '-=0.3')
      .to(".text", {
        y: 0,
      }, '-=0.5')
      .to(".heroButtonSvg", {
        width: 'auto',
        x: 0,
        opacity: 1,
      }, '-=0.9')
  })

  // useGSAP(() => {
  //   const card = document.querySelector(".card");

  //   gsap.to(card, {
  //     yPercent: 35,
  //     ease: "none",
  //     scrollTrigger: {
  //       trigger: card,
  //       start: "top bottom",
  //       end: "bottom top",
  //       scrub: true,

  //     }
  //   });
  // })

  useGSAP(() => {
    gsap.utils.toArray('.img-container').forEach(container => {
      const img = container.querySelector('img');

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          scrub: true,
          pin: false,
        }
      });

      tl.fromTo(img, {
        yPercent: -20,
        ease: 'none'
      }, {
        yPercent: 20,
        ease: 'none'
      });
    })
  })

  // useEffect(() => {
  //   const text = new SplitType('#target', { types: 'words' })
  //   const text1 = new SplitType(text.words, { types: 'words' })
  //   console.log(text1)
  //   gsap.from(text1.words, {
  //     yPercent: 100,
  //     duration: 0.55,
  //     stagger: 0.015
  //   })
  // }, [])


  return (
    <section data-scroll-section className=''>
      <div className='bg-white px-4 sm:mx-8 pb-5 pt-32 md:mx-8 md:pt-48 md:pb-10 md:p-5'>
        <div className='flex flex-col items-center '>
          <div className='hero-text flex flex-col items-center m-7 pb-4 md:pb-8 w-full'>
            <p id='target' ref={heroTextRef} className='font-avantGardeDemi text-heroLightText text-3xl md:text-4xl lg:text-[2.6rem] leading-snug lg:leading-normal w-11/12 lg:w-5/6 text-center '>
              A <span className='text-darkText'>construction</span> firm located in <span className='text-darkText'>Patna, India</span>. We <span className='text-darkText'>construct</span> beautiful <span className='text-darkText'>buildings</span>, craft <span className='text-darkText'>tangible homes</span>, and architect the <span className='text-darkText'>landmark of future.</span>
            </p>
          </div>

          {!isMobile && (
            <a href='/raiseaquote'>
              <Magnetic>
                <button id='heroButton' className="heroButton flex justify-center items-center font-interSemibold bg-black text-white text-base md:text-base lg:text-lg py-[0.85rem] md:py-[1.2] lg:py-[0.85rem] px-8 md:px-10 rounded-full">
                  <span className='text-wrapper'>
                    <span className='text'>Raise a Quote</span>
                  </span>
                  <div className='heroButtonSvg ml-[0.3rem] md:ml-[0.5rem]'>
                    <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.05 15.625C14.0875 13.675 15.5625 11.6625 15.5625 8.3C15.3625 8.3625 15.1875 8.3625 15.0125 8.3625C13.425 8.3625 11.8875 7.2875 11.8875 5.35C11.8875 3.3375 13.175 2.0875 15.0125 2.0875C17.3875 2.0875 18.75 3.9875 18.75 7.4C18.75 12.15 16.5625 15.5625 12.475 17.925L11.05 15.625ZM2.3 15.625C5.3375 13.675 6.8125 11.6625 6.8125 8.3C6.6125 8.3625 6.4375 8.3625 6.2625 8.3625C4.675 8.3625 3.1375 7.2875 3.1375 5.35C3.1375 3.3375 4.425 2.0875 6.2625 2.0875C8.625 2.0875 9.9875 3.9875 9.9875 7.4C9.9875 12.15 7.8 15.5625 3.7125 17.925L2.2875 15.625L2.3 15.625Z" fill="white" />
                    </svg>
                  </div>
                </button>
              </Magnetic>
            </a>

          )}
          {isMobile && (
            <a href='/raiseaquote'>
              <button id='heroButton' className="heroButton flex justify-center items-center font-interSemibold bg-black text-white text-base md:text-base lg:text-lg py-[0.85rem] md:py-[1.2] lg:py-[0.85rem] px-8 md:px-10 rounded-full">
                <span className='text-wrapper'>
                  <span className='text'>Raise a Quote</span>
                </span>

                <div className='heroButtonSvg ml-[0.3rem] md:ml-[0.5rem]'>
                  <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.05 15.625C14.0875 13.675 15.5625 11.6625 15.5625 8.3C15.3625 8.3625 15.1875 8.3625 15.0125 8.3625C13.425 8.3625 11.8875 7.2875 11.8875 5.35C11.8875 3.3375 13.175 2.0875 15.0125 2.0875C17.3875 2.0875 18.75 3.9875 18.75 7.4C18.75 12.15 16.5625 15.5625 12.475 17.925L11.05 15.625ZM2.3 15.625C5.3375 13.675 6.8125 11.6625 6.8125 8.3C6.6125 8.3625 6.4375 8.3625 6.2625 8.3625C4.675 8.3625 3.1375 7.2875 3.1375 5.35C3.1375 3.3375 4.425 2.0875 6.2625 2.0875C8.625 2.0875 9.9875 3.9875 9.9875 7.4C9.9875 12.15 7.8 15.5625 3.7125 17.925L2.2875 15.625L2.3 15.625Z" fill="white" />
                  </svg>
                </div>
              </button>
            </a>


          )}


        </div>
        <div className='p-2 mt-8 md:mt-12 relative flex justify-center'>

          <div className="card relative flex justify-center img">
            {/* cover */}
            <figure className="card-cover-container img-container h-[45vh] sm:h-[60vh] md:h-[69vh] lg:h-screen">
              {/* cover image */}
              <img src={HeroImg} alt="HeroImg" className="card-cover w-full object-cover h-[45vh] sm:h-[60vh] md:h-[69vh] lg:h-screen mt-10" />
            </figure>
            <a href='#projects' className='z-10 w-[6.8rem] h-[6.8rem] sm:w-[7.4rem] sm:h-[7.4rem] md:w-[8rem] md:h-[8rem] lg:w-[9.2rem] lg:h-[9.2rem] 2xl:w-[10rem] 2xl:h-[10rem] absolute top-[40%] rounded-full bg-beige flex flex-col justify-center items-center'>
              <div className='w-[1.4rem] h-[1.4rem] sm:w-[1.6rem] sm:h-[1.6rem] md:w-[1.8rem] md:h-[1.8rem] lg:w-[1.9rem] lg:h-[1.9rem] 2xl:w-[2.2rem] 2xl:h-[2.2rem]'>
                <svg viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M41.3923 1.35257C41.7318 1.69209 41.9961 2.06438 42.1852 2.46943C42.3743 2.87448 42.4685 3.33201 42.4678 3.84201L42.3069 37.6153C42.302 38.6387 41.9465 39.5035 41.2404 40.2096C40.5343 40.9157 39.6687 41.272 38.6435 41.2786C37.6201 41.2835 36.7688 40.9464 36.0898 40.2674C35.4108 39.5883 35.0737 38.7371 35.0786 37.7137L35.198 12.6396L6.40541 41.4322C5.67702 42.1606 4.83272 42.516 3.87251 42.4984C2.91229 42.4808 2.0723 42.1121 1.35252 41.3923C0.63104 40.6708 0.271785 39.8197 0.274752 38.8389C0.277719 37.8581 0.6451 37.0036 1.3769 36.2752L30.1052 7.5468L5.03117 7.66626C4.00773 7.67114 3.1565 7.33406 2.47746 6.65502C1.79842 5.97598 1.46135 5.12475 1.46622 4.10132C1.4711 3.07789 1.82744 2.21226 2.53526 1.50444C3.24308 0.796624 4.10785 0.441133 5.12957 0.437971L38.9028 0.277064C39.4145 0.274626 39.8729 0.367959 40.278 0.557065C40.683 0.746172 41.0545 1.01134 41.3923 1.35257Z" fill="black" />
                </svg>
              </div>
              <p className='text-interRegular text-sm md:text-base pt-2 lg:pt-4'>SEE OUR</p> <p className='text-interRegular text-sm md:text-base'>WORK</p>
            </a>
          </div>
          {/* <img src={HeroImg} alt="HeroImg" className="w-full object-cover h-[45vh] sm:h-[60vh] md:h-[69vh] lg:h-screen mt-10" /> */}


        </div>
      </div>
    </section>
  )
}

export default Hero