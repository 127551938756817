

// framer motion & animation imports
import { motion } from "framer-motion";
import { menuAnimation } from "../utils/MenuAnimation";
import { masking, translate, staggerContainer, width } from "../utils/MenuNav";



// Icons Import
import { PiArrowRightLight } from "react-icons/pi";



export default function Menu({ toggleMenu }) {
  const handleMenuClick = () => {
    // Call the toggleMenu function to close the menu
    toggleMenu();
  };

  const links = [
    {
      label: "Projects",
      href: "/all-projects",
    },
    {
      label: "About Us",
      href: "/#about",
    },
    {
      label: "Contact",
      href: "/#contact",
    },
    {
      label: "Raise a Quote",
      href: "/raiseaquote",
    },
  ];

  return (
    <motion.div
      variants={menuAnimation}
      initial="initial"
      animate="animate"
      exit="exit"
      className=" z-20 top-0 fixed  w-screen bg-white font-interSemiBold "
    >
      <div className="mt-12 sm:mt-20 pt-14 pb-8 lg:pb-14 sm:mx-4">
        <div className="grid grid-cols-1 lg:grid-cols-10 px-8 sm:px-10 gap-7">
          {/* Navigation */}
          <motion.div
            variants={staggerContainer}
            initial="hidden"
            animate="show"
            exit="exit"
            className="lg:col-span-4 row-span-2 flex flex-col gap-y-6"
          >
            {links.map((link, index) => {
              return (
                <motion.div
                  key={index}
                  variants={masking}
                  className="font-interSemiBold text-2xl lg:text-[1.8rem] overflow-hidden"
                >
                  <motion.a
                    variants={translate}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    className="flex justify-between items-center group font-interMedium"
                  >
                    <a onClick={handleMenuClick} href={link.href} className="group-hover:translate-x-4 transition-all duration-200 ease-[cubic-bezier(0.22, 1, 0.36, 1)]">
                      {link.label}
                    </a>
                    <PiArrowRightLight className="group-hover:-translate-x-4 transition-all duration-200 ease-[cubic-bezier(0.22, 1, 0.36, 1)]" />
                  </motion.a>
                  <motion.div
                    variants={width}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    className="mt-2 h-[1px] bg-black"
                  ></motion.div>
                </motion.div>
              );
            })}
          </motion.div>

          {/* email and phone section */}
          <motion.div
            variants={staggerContainer}
            initial="hidden"
            animate="show"
            exit="exit"
            className="col-span-1 lg:col-span-4 lg:col-start-7 lg:row-start-2 text-xl lg:text-[1.8rem] font-interMedium pt-3 lg:pt-0"
          >
            <motion.div
              variants={masking}
              className="overflow-hidden"
            >
              <motion.div
                variants={translate}
                initial="initial"
                animate="animate"
                exit="exit"
                className="flex flex-col gap-1 lg:gap-3"
              >
                <p>support@concicon.com</p>
                <p>+91 92349 57396</p>
              </motion.div>
            </motion.div>


          </motion.div>

        </div>
      </div>
    </motion.div>
  );
}
